@charset "utf-8";

/* All */
header, footer, nav, section, article, aside, figure, figcaption {display:block}
a img {border:0}


/* reset style */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, big, cite, code,
del, dfn, em, font, img, ins, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead, tr, th, td 
{margin:0; padding:0; border:0; font-style:normal; font-weight:normal; line-height:1;}


*, *:before, *:after {-webkit-box-sizing:border-box; box-sizing:border-box;}
* {word-wrap:break-word; word-break:break-word;}


/* default style  */
html,body			{width:100%; height:100%;}
html				{filter: expression(document.execCommand('BackgroundImageCache', false, true)); -webkit-overflow-scrolling:touch; -webkit-user-select:none; -khtml-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none;}
body				{margin:0; padding:0; font-family:'RobotoR','NotoKrR',sans-serif; font-size:12px; color:#696e76; background-color:#f1f3f5; letter-spacing:-.02em;}
table				{font-size:12px; border-spacing:0; border-collapse:collapse;}
td,th				{vertical-align:middle; font-size:12px; font-family:'RobotoR','NotoKrR',sans-serif;}
dd,dt,ol,dl,ul,li	{list-style:none; margin:0; padding:0;}
button, label		{padding:0; border:none; vertical-align:middle; font-family:'RobotoR','NotoKrR',sans-serif; outline:none;}
address, caption, cite, code, dfn, strong, th, var {font-style:normal;}
legend,caption		{display:none; height:0; width:0; font-size:0;}
p					{margin:0;}
blockquote, q		{quotes:none;}
ins					{text-decoration:none;}
del					{text-decoration:line-through;}
hr					{display:block; -webkit-margin-before:0.5em; -webkit-margin-after:0.5em; -webkit-margin-start:auto; -webkit-margin-end:auto;}
img, fieldset		{border:0 none; vertical-align:middle;}
img					{max-width:100%;}
a					{outline:none; select-dummy:expression(this.hideFocus=true); -webkit-tap-highlight-color:transparent;}
select				{display:inline-block; vertical-align:middle; margin:0; padding:0; width:100%; height:30px; font-family:'RobotoR','NotoKrR',sans-serif; font-size:12px; color:#333; border:none; background-color:#fff;}
textarea			{display:block; margin:0; padding:0; width:100%; font-family:'RobotoR','NotoKrR',sans-serif; font-size:1.2em; color:#000; border:none; -webkit-appearance:none; resize:none;}
input, textarea		{outline:none;}

input{
	padding:0;
	width:100%;
	font-family:'RobotoM','NotoKrM';
	font-size:20px;
	color:#26282c;
	border:none;
	background-color:transparent;
	vertical-align:middle;
	-webkit-appearance:none;
	appearance:none;
}
input[type="radio"], input[type="checkbox"] {
	vertical-align:middle;
	margin:0;
	padding:0;
}
input,textarea, button, select		{border-radius:0; -webkit-border-radius:0;}
input[readonly]						{background-color:#fff;}

input::-webkit-input-placeholder	{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
input:-ms-input-placeholder			{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
input::-moz-placeholder				{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
input:-moz-placeholder				{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}

textarea::-webkit-input-placeholder	{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
textarea:-ms-input-placeholder		{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
textarea::-moz-placeholder			{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}
textarea:-moz-placeholder			{font-family:'RobotoR','NotoKrR'; color:#c6cbd0;}


* {-webkit-text-size-adjust:none;}/* 폰트 자동 확대 방지 */
::-webkit-scrollbar {display:none;}/* scrollbar hide */


/* Link style */
a:link					{text-decoration:none;}
a:visited				{text-decoration:none;}
a:hover					{text-decoration:none;}
a:active				{text-decoration:none;}
a:focus					{text-decoration:none;}
a.self					{text-decoration:none;}


/* etc define */
.underline {text-decoration:underline;}
.font-default {color:#333;}
.bold {font-weight:bold;}
.line {border-bottom:1px solid #d3d3d3;}
.floatLeft {display:inline; float:left;}
.floatRight {display:inline; float:right;}
.clearBoth {clear:both;}
.alignMiddle {vertical-align:middle;}
.alignTop {vertical-align:top;}
.alignBottom {vertical-align:bottom;}
.alignLeft {text-align:left !important;}
.alignCenter {text-align:center !important;}
.alignRight {text-align:right !important;}
.borderTop {border-top:1px solid #eee;}
.borderTopNone {border-top:none !important;}
.borderBtmNone {border-bottom:none !important;}
.borderNone {border:none !important;}
.borderBottom {border-bottom:1px solid #ddd !important;}
.txtspace {letter-spacing:-1px;}
.displayInlineBblock {display:inline-block;}
.boxShadowNone	{box-shadow:none !important;}
.overflowHidden {overflow:hidden;}
.cursorDefault {cursor:default !important;}
.new-line			{white-space: pre-line}
.leftzero			{padding-left: 0px}
.longtitle			{padding: 0 45px}


hr													{display:block; margin:0; padding:0; height:60px; border:none;}
button:disabled										{opacity:.25; cursor:default;}


/* Button Style */
.btn_submit											{display:block; width:100%; font-size:14px; height:48px; color:#26282c; background-color:transparent; border:1px solid #8c949e; border-radius:8px;}
.btn_login,
.btn_line											{display:block; width:100%; font-size:15px; height:52px; border-radius:8px;}
.btn_login											{color:#fff; background-color:#01a368;}
.btn_line											{margin-bottom:48px; color:#26282c; background-color:#fff; border:1px solid#8c949e;}
.btn_standard,
.btn_cancel											{width:100%; font-family:'RobotoM','NotoKrM'; font-size:16px; height:54px; border-radius:16px;}
.btn_standard										{color:#fff; background-color:#01a368;}
.btn_cancel											{color:#484b51; background-color:#f2f4f6;}


/* Sticky Button Fixed */
.sticky_button										{position:fixed; bottom:0; left:0; right:0; z-index:2; padding:0 16px 16px; background-color:#fff; box-shadow:0 -16px 24px 0 rgba(255,255,255,1);}
.sticky_button strong								{font-family:'RobotoB','NotoKrB'; color:#facc56;}
.sticky_button .skip								{display:flex; justify-content:center; align-items:center; margin-bottom:-16px; width:100%; height:64px; font-size:14px;}
.sticky_button .skip span							{margin-right:12px; color:#aab0b8;}
.sticky_button .skip a								{font-family:'RobotoB','NotoKrB'; color:#01a368;}
.sticky_button .skip button							{height:16px; font-size:14px; color:#696e76; border-bottom:1px solid #696e76; background-color:transparent;}
.sticky_button .wrap_check							{margin:24px 0;}


/* Bottom Sheet Button */
.bottom_sheet_button								{display:flex; padding:0 16px 16px;}
.bottom_sheet_button button							{margin-right:10px;}
.bottom_sheet_button button:last-child				{margin-right:0;}


/* Layout Selector */
/*.wrap												{min-width:360px; overflow:hidden;}*/
.container:before,
.contents:before,
.based:before										{content:""; position:fixed; top:0; left:0; z-index:-1; width:100%; height:100%;}
.container											{margin-bottom:94px; padding:0 24px;}
.container:before,
.based:before										{background-color:#fff;}
.contents:before									{background-color:#f8f9fb;}
.scroll_container									{flex:1 1 0; display:flex; flex-direction:column; max-height:50vh; overflow-y:auto;}
aside > ul li:first-child							{border-top:none;}


/* Intro */
.intro												{display:flex; justify-content:center; align-items:center; height:100vh; background-color:#353536;}
.intro .animate										{position:relative; display:flex; width:147px; height:64px;}
.intro .animate span								{height:34px; text-indent:-9999px; background:url('/src/images//sub/intro_logo.png') no-repeat; background-size:auto 34px; opacity:0; animation-duration:1.5s; animation-delay:.5s; animation-fill-mode:forwards;}
.intro .animate span:nth-child(1)					{width:34px; background-position-x:0; transform:translateX(-86px); animation-name:K; animation-delay:.85s;}
.intro .animate span:nth-child(2)					{width:30px; background-position-x:-34px; transform:translateX(-140px); animation-name:G;}
.intro .animate span:nth-child(3)					{width:30px; background-position-x:-64px; transform:translateY(-64px) rotate(-720deg); animation-name:O;}
.intro .animate span:nth-child(4)					{width:26px; background-position-x:-94px; transform:translateX(140px); animation-name:L;}
.intro .animate span:nth-child(5)					{width:27px; background-position-x:right; transform:translateX(93px); animation-name:F; animation-delay:.85s;}
.intro .animate span:nth-child(6)					{position:absolute; bottom:-20px; left:50%; width:82px; height:14px; background:url('/src/images//sub/intro_text.png') no-repeat; background-size:100%; transform:translateX(-50%); animation-name:kgolf; animation-delay:1.5s;}
@keyframes K {100% {transform:translateX(0); opacity:1;}}
@keyframes G {100% {transform:translateX(0); opacity:1;}}
@keyframes O {100% {transform:translateY(0) rotate(360deg); opacity:1;}}
@keyframes L {100% {transform:translateX(0); opacity:1;}}
@keyframes F {100% {transform:translateX(0); opacity:1;}}
@keyframes kgolf {100% {bottom:0; opacity:.25;}}


/* 상세페이지 단락 */
.section > li										{position:relative; display:flex; flex-direction:column; margin-bottom:8px; padding:32px 20px 40px; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.section > li > em,
.section > li > .alter em							{font-family:'RobotoB','NotoKrB'; font-size:17px; color:#26282c;}
.section > li > em									{margin-bottom:24px;}
.section > li > label								{position:absolute; top:28px; right:20px; display:flex; align-items:center; padding:0 32px 0 12px; height:24px; font-family:'RobotoM','NotoKrM'; font-size:12px; color:#484b51; border:1px solid #ebeef0; border-radius:12px;}
.section > li > label:after							{content:""; position:absolute; top:50%; right:10px; width:0; height:0; border-style:solid; border-width:6px 4px 0 4px; border-color:#01a368 transparent transparent transparent; transform:translateY(-50%);}
.section > li > .alter								{display:flex; align-items:center; margin-top:-5px; padding-bottom:19px;}
.section > li > .alter label						{position:relative; display:flex; align-items:center; margin-left:12px; padding:0 32px 0 12px; height:27px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#484b51; letter-spacing:0; background-color:#f1f3f5; border-radius:14px; transform:translateY(-1px);}
.section > li > .alter label:after					{content:""; position:absolute; top:50%; right:10px; width:0; height:0; border-style:solid; border-width:6px 4px 0 4px; border-color:#01a368 transparent transparent transparent; transform:translateY(-50%);}
.section > li > .alter button						{position:relative; margin-left:auto; width:27px; height:27px; text-indent:-9999px; background-color:transparent;}
.section > li > .alter button:before,
.section > li > .alter button:after					{content:""; position:absolute; top:50%; right:8px; z-index:1; width:2px; height:8px; background-color:#aab0b8; border-radius:1px;}
.section > li > .alter button:before				{margin-top:-6px; transform:rotate(-45deg);}
.section > li > .alter button:after					{margin-top:-1.5px; transform:rotate(45deg);}


/* 게임타입 */
.game_type											{display:flex; align-items:center; padding:0 12px; height:18px; font-family:'RobotoB','NotoKrB'; font-size:11px; color:#fff; border-radius:9px; transform:translateY(-1px); letter-spacing:0;}
.game_type.stroke									{background-color:#96e676;}
.game_type.foursome									{background-color:#facc56;}
.game_type.match									{background-color:#69adff;}
.game_type.practice									{font-family:'RobotoR','NotoKrR'; background-color:#26282c;}
.game_type.scramble									{background-color:#ff7040;}


/* 게임구분 */
.category_type										{display:flex; align-items:center; padding:0 8px; height:18px; font-family:'RobotoB','NotoKrB'; font-size:11px; color:#fff; background-image:linear-gradient(to right, #26A7E8 0%, #4188F3 100%); border-radius:1px; transform:translateY(-1px); letter-spacing:0;}


/* Wrap Check */
.wrap_check											{display:flex;}
.wrap_check a										{position:relative; display:flex; align-items:center;}
.wrap_check a input									{width:0; height:0;}
.wrap_check a label									{position:relative; padding-left:28px; margin-right:24px; font-size:14px; color:#26282c;}
.wrap_check a label:before,
.wrap_check a label:after							{content:""; position:absolute; top:50%; z-index:1; border-radius:50%; transform:translateY(-50%);}
.wrap_check a label:before							{left:0; width:22px; height:22px; border:2px solid #e5e8eb; transition-duration:.3s;}
.wrap_check a label:after							{left:8px; width:6px; height:6px; background-color:#f9f9f9; transition:all .1s;}
.wrap_check a input:checked + label:before			{border-color:#01a368;}
.wrap_check a input:checked + label:after			{left:6px; width:10px; height:10px; background-color:#01a368;}
.wrap_check a:last-child label						{margin-right:0;}
.wrap_check a label.rows							{line-height:20px;}
.wrap_check a label.rows:before						{top:-1px; transform:none;}
.wrap_check a label.rows:after						{top:7px; transform:none;}
.wrap_check a input:checked + label.rows:after		{top:5px;}


/* Navigation */
nav													{position:fixed; bottom:0; left:0; right:0; z-index:9998; display:flex; align-items:stretch; height:48px; background-color:#fff; border-radius:16px 16px 0 0; transition:.3s;}
nav button,
nav a												{position:relative; width:100%; text-indent:-9999px; background-color:transparent;}
nav button:after									{content:""; position:absolute; top:50%; left:50%; z-index:1; width:22px; height:22px; background:url('/src/images//icon/nav_icon.png') no-repeat; background-size:auto 44px; transform:translate(-50%,-50%);}
nav button:nth-child(1):after						{background-position:0 top;}
nav button:nth-child(2):after						{background-position:-22px top;}
nav button:nth-child(4):after						{background-position:-88px top;}
nav button:nth-child(5):after						{background-position:-66px top;}
nav button.on:after									{background-position-y:bottom;}
nav button.new:before							{content:""; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); z-index:2; width:5px; height:5px; background-color:#ff8094; border-radius:50%; margin-top:-12px; margin-left:12px;}
nav a:before,
nav a:after											{content:""; position:absolute; top:-10px; left:50%; z-index:1; width:52px; height:52px; transform:translateX(-50%);}
nav a:before										{background-color:#26282c; border-radius:50%; box-shadow:0 2px 2px 0 rgba(0,0,0,.2);}
nav a:after											{background-image:url('/src/images//icon/qr_icon.png'); background-size:100%;}
nav.dissolve										{z-index:1;}


/* 전체메뉴 */
.fullMenu_wrap										{position:fixed; top:0; left:0; z-index:-1; width:100%; height:100%; transition:all .2s;}
.fullMenu_wrap:before								{content:""; position:absolute; top:0; left:0; z-index:1; width:100%; height:100%; background-color:rgba(0,0,0,.6); opacity:0; transition:all .2s;}
.fullMenu_wrap.on									{z-index:9000;}
.fullMenu_wrap.on:before							{opacity:1;}
.fullMenu_wrap.on .outside_close					{left:0;}
.fullMenu_wrap.on .slide_container					{left:40px;}
.fullMenu_wrap .outside_close						{position:fixed; top:0; bottom:0; left:100%; right:0; z-index:1; text-indent:-9999px; background-color:transparent;}
.fullMenu_wrap .slide_container						{position:relative; top:0; left:100%; z-index:1; display:flex; flex-direction:column; padding:54px 64px 48px 24px; width:100%; height:100vh; background-color:#fff; border-radius:20px 0 0 0; transition:all .2s ease-out;}
.fullMenu_wrap .slide_container .header				{position:relative; height:54px;}
.fullMenu_wrap .slide_container .header > a			{position:absolute; top:-42px; right:-12px; z-index:1; width:24px; height:24px; text-indent:-9999px;}
.fullMenu_wrap .slide_container .header > a:before,
.fullMenu_wrap .slide_container .header > a:after	{content:""; position:absolute; top:-2px; left:50%; z-index:1; margin-left:-1px; width:1px; height:28px; background-color:#26282c;}
.fullMenu_wrap .slide_container .header > a:before	{transform:rotate(45deg);}
.fullMenu_wrap .slide_container .header > a:after	{transform:rotate(-45deg);}
.fullMenu_wrap .slide_container .header strong		{font-family:'RobotoB','NotoKrB'; font-size:24px; color:#26282c;}
.fullMenu_wrap .menu_all							{flex:1 1 0; display:flex; flex-direction:column; overflow-y:auto;}
.fullMenu_wrap .main_menu							{display:flex; margin-bottom:28px; padding:16px 12px; background-color:#f5faf9; border-radius:10px;}
.fullMenu_wrap .main_menu li						{position:relative; display:flex; justify-content:center; width:100%;}
.fullMenu_wrap .main_menu li.on:after				{content:""; position:absolute; top:0; left:50%; z-index:1; margin-left:20px; width:8px; height:8px; background-color:#ff8094; border-radius:50%;}
.fullMenu_wrap .main_menu li a						{position:relative; padding:66px 0 4px; min-width:56px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#26282c; text-align:center;}
.fullMenu_wrap .main_menu li a:before,
.fullMenu_wrap .main_menu li a:after				{content:""; position:absolute; left:50%; z-index:1; transform:translateX(-50%);}
.fullMenu_wrap .main_menu li a:before				{top:0; width:56px; height:56px; background-color:#fff; border-radius:50%;}
.fullMenu_wrap .main_menu li a:after				{top:14px; width:28px; height:28px; background:url('/src/images//icon/main_menu_icon.png') no-repeat; background-size:auto 28px;}
.fullMenu_wrap .main_menu li:nth-child(1) a:after	{background-position:0 top;}
.fullMenu_wrap .main_menu li:nth-child(2) a:after	{background-position:-28px top;}
.fullMenu_wrap .main_menu li:nth-child(3) a:after	{background-position:-56px top;}
.fullMenu_wrap .category_menu						{display:flex; flex-direction:column; margin-bottom:40px;}
.fullMenu_wrap .category_menu a						{position:relative; display:flex; align-items:center; margin-bottom:10px; padding-left:38px; height:44px; font-family:'RobotoM','NotoKrM'; font-size:16px; color:#26282c; transition:all .2s;}
.fullMenu_wrap .category_menu a:before,
.fullMenu_wrap .category_menu a:after				{content:""; position:absolute;}
.fullMenu_wrap .category_menu a:before				{top:0; bottom:0; left:60px; right:60px; z-index:-1; background-color:transparent; border-radius:12px; transition:all .15s ease-out;}
.fullMenu_wrap .category_menu a:after				{top:50%; left:0; z-index:1; width:24px; height:24px; background:url('/src/images//icon/category_menu_icon.png') no-repeat; background-size:auto 24px; transform:translateY(-50%); transition:all .2s;}
.fullMenu_wrap .category_menu a.i_record:after		{background-position:0 top;}
.fullMenu_wrap .category_menu a.i_shot:after		{background-position:-24px top;}
.fullMenu_wrap .category_menu a.i_statistic:after	{background-position:-48px top;}
.fullMenu_wrap .category_menu a.i_match:after		{background-position:-72px top;}
.fullMenu_wrap .category_menu a.i_store:after		{background-position:-96px top;}
.fullMenu_wrap .category_menu a.i_customer:after	{background-position:-120px top;}
.fullMenu_wrap .category_menu a.i_setting:after		{background-position:-144px top;}
.fullMenu_wrap .category_menu a:hover				{text-indent:8px;}
.fullMenu_wrap .category_menu a:hover:before		{left:0; right:0; background-color:#f1f3f5;}
.fullMenu_wrap .category_menu a:hover:after			{left:8px;}
.fullMenu_wrap .category_menu a > .new					{width:5px; height:5px; background-color:#ff8094; border-radius:50%; text-indent:-9999px; margin-left:5px; margin-top:-15px;}



/* Flexible Slider */
.flexslider											{position:relative; padding-bottom:44px; text-align:center;}
.flexslider .flex-viewport							{overflow:hidden; border-radius:4px;}
.flexslider .flex-viewport .slides li				{display:none; -webkit-backface-visibility:hidden;}


/* Control Nav */
.flex-control-nav									{position:absolute; bottom:24px; left:0; right:0; z-index:1; display:flex; justify-content:center; align-items:center;}
.flex-control-nav li								{padding:0 3px;}
.flex-control-paging li a							{display:block; width:6px; height:6px; text-indent:-9999px; background-color:#ebeef0; border-radius:50%; transition:all .4s;}
.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active				{background-color:#8c949e;}


/* Team Color */
i.team_1											{color:#6db5ff;}
i.team_2											{color:#ff8094;}
i.team_3											{color:#96e676;}
i.team_4											{color:#facb55;}


/* Main Header */
.main_head											{position:relative; display:flex; justify-content:space-between; align-items:center; padding:0 24px; height:54px;}
.main_head:before									{content:""; position:absolute; top:0; left:0; z-index:-1; width:100%; height:290px; background-image:linear-gradient(to bottom, #057A8C 10%, #2CAF90 60%, #01A368 100%); border-radius:0 0 0 48px;}
.main_head *										{text-indent:-9999px; background-repeat:no-repeat; background-size:100%;}
.main_head > span									{width:74px; height:16px; background-image:url('/src/images//top/logo_w.png');}
.main_head > a										{position:relative; width:24px; height:24px; background-image:url('/src/images//top/top_push.png');}
.main_head > a.on:after								{content:""; position:absolute; top:0; right:0; z-index:1; width:5px; height:5px; background-color:#ff8094; border-radius:50%;}


/* Header */
header												{position:relative; display:flex; justify-content:center; align-items:center; height:48px; background-color:#fff; transition:all .3s;}
header.fixed										{position:fixed; top:0; left:0; z-index:10; width:100%; box-shadow:0 1px 0 0 rgba(105,110,118,.1);}
header button,
header a											{position:absolute; top:50%; width:24px; height:24px; text-indent:-9999px; background-color:transparent; background-repeat:no-repeat; background-size:auto 100%; transform:translateY(-50%);}
header button										{left:16px; background-image:url('/src/images//top/top_back.png');}
header a											{right:16px;}
header a.i_share									{background-image:url('/src/images//top/top_share.png');}
header em,
header span											{font-family:'RobotoM','NotoKrM'; font-size:16px; color:#060b11;}
header.align										{justify-content:flex-start; padding-left:60px;}
header.convert										{position:fixed; top:0; left:0; z-index:10; width:100%; justify-content:flex-start; padding-left:60px; box-shadow:0 1px 0 0 rgba(105,110,118,.1);}
header.turn											{background-color:transparent; box-shadow:none;}
header.turn em										{display:none;}
header.turn button,
header.turn a										{background-position-x:right;}
header.turn span,
header.turn label									{color:#fff;}
header.turn label									{border-color:rgba(255,255,255,.2);}


/* Top Selection */
.top_selection										{position:absolute; top:50%; right:16px; display:flex; align-items:center; padding:0 32px 0 12px; height:26px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#26282c; letter-spacing:0; border:1px solid #ebeef0; border-radius:13px; transform:translateY(-50%);}
.top_selection:after								{content:""; position:absolute; top:50%; right:10px; width:0; height:0; border-style:solid; border-width:6px 4px 0 4px; border-color:#01a368 transparent transparent transparent; transform:translateY(-50%);}


/* Member Info */
.memberinfo											{display:flex; align-items:center; margin-top:20px; padding:0 24px; letter-spacing:0;}
.memberinfo p										{position:relative; flex:0 0 44px; height:44px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%; box-shadow:0 2px 6px 0 rgba(0,0,0,.05);}
.memberinfo p:after									{content:""; position:absolute; top:0; left:0; z-index:1; width:100%; height:100%; border:1px solid rgba(255,255,255,.2); border-radius:50%;}
.memberinfo .log_sec								{display:flex; flex-direction:column; padding-left:16px; overflow:hidden;}
.memberinfo .log_sec em								{margin-bottom:6px; font-family:'RobotoB','NotoKrB'; font-size:18px; color:#fff; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
.memberinfo .log_sec span b							{position:relative; font-family:'RobotoL'; font-size:11px; color:rgba(255,255,255,.6);}
.memberinfo .log_sec span b:after					{content:""; position:absolute; top:50%; left:80%; z-index:1; width:12px; height:12px; background:url('/src/images//icon/mem_i.png') no-repeat; background-size:100%; transform:translateY(-50%);}


/* Record Info */
.record												{display:flex; margin-top:24px; padding:0 16px; letter-spacing:0;}
.record dl											{position:relative; display:flex; flex-direction:column; width:100%; color:#fff; text-align:center;}
.record dl:after									{content:""; position:absolute; top:0; bottom:0; right:0; z-index:1; width:1px; background-color:rgba(0,0,0,.07);}
.record dl:last-child:after							{background-color:transparent;}
.record dl dt										{margin-bottom:5px; font-family:'RobotoB'; font-size:36px;}
.record dl dd										{font-family:'RobotoL'; font-size:12px;}


/* Record Info */
.main_section										{margin-top:24px; padding:0 16px 56px;}
.main_section section								{position:relative; margin-bottom:12px; padding-left:24px; padding-right:24px; background-color:#fff; border-radius:20px;}
.main_section section > a							{position:relative; display:flex; justify-content:space-between; align-items:center; padding-right:24px;}
.main_section section > a em						{padding:16px 0; font-family:'RobotoB','NotoKrB'; font-size:20px; color:#26282c;}


/* Section Arrow */
.s_arrow:before,
.s_arrow:after										{content:""; position:absolute; top:50%; right:3px; z-index:1; width:2px; height:8px; background-color:#aab0b8; border-radius:1px;}
.s_arrow:before										{margin-top:-7px; transform:rotate(-45deg);}
.s_arrow:after										{margin-top:-2.5px; transform:rotate(45deg);}


/* 최근 라운드 */
.round_record .norecord,
.round_record .roundinfo							{display:flex; align-items:center; height:110px; letter-spacing:0;}
.round_record .norecord p							{flex:0 0 48px; height:48px; text-indent:-9999px; background:#f1f3f5 url('/src/images//character/no_data_round.png') no-repeat center; background-size:cover; border-radius:50%;}
.round_record .norecord span						{position:relative; padding-left:16px; font-size:12px; color:#26282c; line-height:18px;}
.round_record .norecord span strong					{font-weight:normal; color:#01a368;}
.round_record .roundinfo .score						{flex:none; display:flex; flex-direction:column; padding-left:4px; text-align:center;}
.round_record .roundinfo .score em					{font-family:'RobotoB'; font-size:36px; color:#26282c;}
.round_record .roundinfo .score span				{font-family:'RobotoL'; font-size:12px; color:#8c949e;}
.round_record .roundinfo .recent					{position:relative; display:flex; flex-wrap:wrap; padding:0 28px; width:100%; color:#26282c; overflow:hidden;}
.round_record .roundinfo .recent span				{margin-bottom:8px; font-family:'RobotoL'; font-size:13px;}
.round_record .roundinfo .recent strong				{width:100%; font-family:'RobotoB','NotoKrB'; font-size:17px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;padding-bottom: 1px;}
.round_record .roundinfo .recent .game_type			{margin-top:12px;}


/* 최근 샷 정보 
.my_shot											{display:flex; align-items:center; overflow:hidden;}
.my_shot p											{position:relative; flex:0 0 100px; margin-left:-24px; height:100px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:0 20px 20px 0; overflow:hidden;}
.my_shot p:before,
.my_shot p:after									{content:""; position:absolute;}
.my_shot p:before									{top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.2);}
.my_shot p:after									{top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/thumb_play.png') no-repeat center; background-size:100%; transform:translate(-50%,-50%);}
.my_shot ul											{padding-left:20px; width:100%; letter-spacing:0;}
.my_shot ul li										{display:flex; align-items:center; padding:5px 0;}
.my_shot ul li strong								{margin-right:8px; font-family:'RobotoB','NotoKrB'; font-size:16px; color:#26282c;}
.my_shot ul li dl									{display:flex; flex-direction:column; width:100%;}
.my_shot ul li dl dd								{margin-bottom:4px; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#8c949e;}
.my_shot ul li dl dt								{font-family:'RobotoL'; font-size:20px; color:#01a368;}
.my_shot ul li dl dt small							{margin-left:1px; font-size:12px; color:#8c949e;}
*/

/* 최근 샷 정보 */
.my_shot											{display:flex; align-items:center; padding-bottom:8px;}
.my_shot p											{position:relative; flex:0 0 80px; margin-left:-8px; height:80px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:12px; overflow:hidden;}
.my_shot p:before,
.my_shot p:after									{content:""; position:absolute;}
.my_shot p:before									{top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.2);}
.my_shot p:after									{top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/thumb_play.png') no-repeat center; background-size:100%; transform:translate(-50%,-50%);}
.my_shot ol											{padding-left:16px; width:100%; letter-spacing:0;}
.my_shot ol li										{display:flex; align-items:center; padding:5px 0;}
.my_shot ol li strong								{margin-right:8px; font-family:'RobotoB','NotoKrB'; font-size:16px; color:#26282c;}
.my_shot ol li dl									{display:flex; flex-direction:column; width:100%;}
.my_shot ol li dl dd								{margin-bottom:4px; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#8c949e;}
.my_shot ol li dl dt								{font-family:'RobotoL'; font-size:20px; color:#01a368;}
.my_shot ol li dl dt small							{margin-left:1px; font-size:12px; color:#8c949e;}



/* 예약정보 */
.info_reservation									{padding:8px 0;}
.info_reservation > a span							{font-size:16px; color:#01a368;}
.info_reservation > a i								{display:none; position:absolute; top:4px; right:0; z-index:1; width:40px; height:40px; text-indent:-9999px; background:url('/src/images//icon/i_dday.png') no-repeat center; background-size:100%;}
.info_reservation.d-day > a:before,
.info_reservation.d-day > a:after,
.info_reservation.d-day > a span					{opacity:0;}
.info_reservation.d-day > a i						{display:block;}
.info_reservation ul								{padding:4px 0;}
.info_reservation ul li								{position:relative; display:flex; align-items:center; margin-bottom:12px; padding-left:12px; color:#26282c;}
.info_reservation ul li:before						{content:""; position:absolute; top:50%; left:2px; width:4px; height:4px; background-color:#01a368; border-radius:50%; transform:translateY(-50%);}
.info_reservation ul li span						{margin-right:12px; font-family:'RobotoL','NotoKrL'; font-size:16px;}
.info_reservation ul li b							{padding-right:60px; font-family:'RobotoM','NotoKrM'; font-size:16px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
.info_reservation .summary							{display:flex; flex-wrap:wrap; margin-bottom:16px; padding-left:10px;}
.info_reservation .summary label					{display:flex; align-items:center; margin-right:6px; padding:0 6px; height:20px; font-family:'RobotoM','NotoKrM'; font-size:12px; color:#26282c; border:1px solid #c6cbd0; border-radius:2px;}
.info_reservation button							{position:absolute; bottom:24px; right:24px; z-index:1; width:48px; height:48px; text-indent:-9999px; background:url('/src/images//icon/i_call.png') no-repeat center; background-size:20px; border:1px solid #f1f3f5; border-radius:50%;}


/* 매장찾기 */
.find_store											{padding-top:8px;}
.find_store .store_wrap								{padding-top:13px;}


/* Tab Line */
.tab_line											{position:relative; display:flex;}
.tab_line:after										{content:""; position:absolute; bottom:0; left:-24px; right:-24px; z-index:0; height:1px; background-color:#ebeef0;}
.tab_line button									{position:relative; padding:0 18px; height:44px; font-size:15px; color:#8c949e; background-color:transparent; transition:all .2s;}
.tab_line button:after								{content:""; position:absolute; bottom:0; left:50%; z-index:1; width:0; height:2px; background-color:transparent; transform:translateX(-50%); transition:all .4s;}
.tab_line button.active								{color:#26282c;}
.tab_line button.active:after						{width:100%; background-color:#26282c;}


/* Sub Tab Button */
.tab_button											{position:relative; display:flex; justify-content:space-between; margin-top:-4px; padding:4px 20px 0; background-color:#fff; overflow: hidden;}
.tab_button:after									{content:""; position:absolute; bottom:0; left:-24px; right:-24px; z-index:0; height:1px; background-color:#ebeef0;}
.tab_button.fixed									{position:fixed; top:48px; left:0; z-index:10; width:100%;}
.tab_button button									{position:relative; margin:0 10px; width:100%; height:44px; font-size:15px; color:#26282c; background-color:transparent; transition:all .2s;}
.tab_button button:after						{content:""; position:absolute; bottom:0; left:50%; z-index:1; width:0; height:2px; background-color:transparent; transform:translateX(-50%); transition:all .4s;}
.tab_button button.active						{color:#01a368;}
.tab_button button.active:after				{width:100%; background-color:#01a368;}


/* 매장전화예약 */
.btn_store											{flex:none; display:flex; transition:all .2s;}
.btn_store button								{margin-left:8px; width:36px; height:36px; text-indent:-9999px; border:1px solid #f1f3f5; background-image:url('/src/images//icon/i_call.png'); background-repeat:no-repeat; background-position:center; background-size:14px; background-color:#fff; border-radius:50%;}
.btn_store .reserve								{background-image:url('/src/images/icon/i_booking.png'); background-color:rgba(120,137,155,.1);}


/* 매장목록 */
.pack_board											{margin-bottom:48px; padding:0 16px; border-bottom:1px solid #f1f3f5; background-color:#fff;}
.store_list li										{position:relative; display:flex; justify-content:space-between; align-items:center; padding:5px 0; border-bottom:1px solid #f1f3f5;}
.store_list li:last-child							{border-bottom:none;}
.store_list li a									{display:flex; flex-direction:column; padding:12px 0; transition:all .2s;}
.store_list li a:before								{content:""; position:absolute; top:5px; bottom:5px; left:60px; right:60px; z-index:0; background-color:transparent; border-radius:12px; transition:all .15s ease-out;}
.store_list li a:hover								{padding-left:4px;}
.store_list li a:hover:before						{left:-12px; right:-12px; background-color:#f1f3f5;}
.store_list li a:hover + .btn_store					{padding-right:4px;}
.store_list li a strong								{margin-bottom:5px; font-family:'RobotoL'; font-size:12px; color:#26282c; letter-spacing:0;}
.store_list li a p									{display:table; font-size:15px; color:#26282c; line-height:18px;}
.store_list li a p i,
.store_list li a p span								{display:inline-block; margin-left:4px; line-height:16px;}
.store_list li a p i								{width:16px; text-indent:-9999px; background:#01a368 url('/src/images//icon/i_bookmark.png') no-repeat center; background-size:10px; border-radius:50%;}
.store_list li a p span								{font-size:12px; color:#01a368; letter-spacing:0;}
.store_list li a .keyword							{margin-top:5px;}
.store_list li a .keyword span						{margin-right:8px; font-size:12px; color:#c6cbd0;}
.store_list li a strong,
.store_list li a p,
.store_list li a .keyword,
.btn_store											{z-index:1;}


/* Login Header */
.login_head											{position:relative; display:flex; justify-content:center; align-items:center; height:54px;}
.login_head *										{text-indent:-9999px; background-repeat:no-repeat; background-size:100%;}
.login_head > span									{width:74px; height:16px; background-image:url('/src/images//top/logo.png');}
.login_head > a										{position:absolute; top:50%; right:24px; width:30px; height:30px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%; box-shadow:0 1px 4px 0 rgba(0,0,0,.1); transform:translateY(-50%);}


/* Login Title */
.login_title										{display:flex; flex-direction:column; margin:30px 0 36px; padding:0 2px; color:#26282c;}
.login_title strong									{font-family:'RobotoB','NotoKrB'; font-size:24px; line-height:32px;}
.login_title span									{margin-top:12px; font-size:14px; line-height:23px;}


/* Title */
.title												{margin:32px 0; padding:0 2px; font-family:'RobotoB','NotoKrB'; font-size:28px; color:#26282c; line-height:36px;}


/* Write Box Form */
.write_box_form li									{position:relative; display:flex; flex-direction:column-reverse; margin-bottom:8px; padding:0 16px; border:1px solid transparent;}
.write_box_form li label							{display:block; margin-top:18px; font-size:12px; color:#8d8e90; transition:all .15s;}
.write_box_form li input							{padding-right:28px; height:56px;}
.write_box_form li label:before						{content:""; position:absolute; top:0; left:0; z-index:-1; width:100%; height:100%; border:1px solid #d9dadc; border-radius:8px; transition:all .15s;}
.write_box_form li input:focus + label				{color:#01a368;}
.write_box_form li input:focus + label:before		{border-color:#01a368;}
.write_box_form li a								{position:absolute; bottom:19px; right:16px; width:18px; height:18px; text-indent:-9999px; background-color:#4e5151; border-radius:50%;}
.write_box_form li a:after							{content:""; position:absolute; top:50%; left:50%; z-index:1; width:8px; height:8px; background-image:url('/src/images//icon/txt_delete.png'); background-size:100%; transform:translate(-50%,-50%);}
.write_box_form li.on								{border-color:#01a368;}


/* Write Form */
.write_form li										{position:relative; margin-bottom:20px;}
.write_form li > span								{position:relative; display:flex; flex-direction:column-reverse; width:100%;}
.write_form li > span > a							{position:absolute; bottom:14px; right:2px; width:18px; height:18px; text-indent:-9999px; background-color:#4e5151; border-radius:50%;}
.write_form li > span > a:after						{content:""; position:absolute; top:50%; left:50%; z-index:1; width:8px; height:8px; background-image:url('/src/images//icon/txt_delete.png'); background-size:100%; transform:translate(-50%,-50%);}
.write_form li > span > sub							{position:absolute; bottom:15px; right:2px; z-index:1; font-size:14px; color:#f23f3f;}
.write_form li > button								{margin-top:20px;}
.write_form li em									{position:relative; display:flex; align-items:center; margin-top:12px; padding-left:2px; font-size:13px; color:#484b51; transition:.15s;}
.write_form li em i									{position:relative; width:28px; text-indent:-9999px;}
.write_form li em i:before,
.write_form li em i:after							{content:""; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%);}
.write_form li em i:before							{width:16px; height:16px; border:1px solid #eff3f7; border-radius:50%;}
.write_form li em i:after							{width:6px; height:10px; background-image:url('/src/images//icon/i_help.png'); background-size:100%;}
.write_form li em .tooltip							{display:none; position:absolute; top:100%; left:0; z-index:1; margin-top:10px; padding:18px 20px; width:100%; background-color:#353536; border-radius:2px;}
.write_form li em .tooltip.on						{display:block;}
.write_form li em .tooltip p						{padding-right:20px; font-family:'RobotoL','NotoKrL'; font-size:13px; color:rgba(255,255,255,.85); line-height:18px;}
.write_form li em .tooltip a						{position:absolute; top:12px; right:12px; width:24px; height:24px; text-indent:-9999px;}
.write_form li em .tooltip a:before,
.write_form li em .tooltip a:after					{content:""; position:absolute; top:-1px; left:50%; z-index:1; width:1px; height:26px; background-color:#fff;}
.write_form li em .tooltip a:before					{transform:rotate(45deg);}
.write_form li em .tooltip a:after					{transform:rotate(-45deg);}
.write_form li input,
.write_form li > span > label						{padding:0 30px 0 2px; height:45px; border-bottom:1px solid #ebeef0;}
.write_form li > span > label						{position:relative; display:flex; align-items:center; font-family:'RobotoM','NotoKrM'; font-size:20px; color:#26282c;}
.write_form li > span > label:before,
.write_form li > span > label:after					{content:""; position:absolute; top:50%; right:12px; margin-top:-1px; width:2px; height:8px; background-color:#b2b2b2; transition:.15s;}
.write_form li > span > label:before				{margin-right:-1px; transform:rotate(-45deg) translateY(-50%);}
.write_form li > span > label:after					{transform:rotate(45deg) translateY(-50%);}
.write_form li > span > label:hover:before,
.write_form li > span > label:hover:after			{margin-top:-3px;}
.write_form li > span > label:hover:before			{margin-right:-3px; transform:rotate(135deg);}
.write_form li > span > label:hover:after			{margin-right:2px; transform:rotate(-135deg);}
.write_form li > span > label:hover,
.write_form li input:focus							{border-bottom:2px solid #01a368; transition:.15s;}
.write_form li label:hover + em,
.write_form li input:focus + em						{color:#01a368;}
.write_form li label sub							{margin-right:8px; font-family:'RobotoR','NotoKrR'; font-size:14px; color:#8c949e; transform:translateY(1px);}
.write_form.group_field li							{display:flex; align-items:flex-end; margin-bottom:16px;}
.write_form.group_field li > span					{margin-right:10px;}
.write_form.group_field li > span:last-child		{margin-right:0;}
.write_form.group_field li input:focus + em			{color:#484b51;}
.write_form.group_field li > button					{flex:none; margin-bottom:5px; padding:0 20px; height:36px; font-family:'RobotoM','NotoKrM'; font-size:14px; color:#696e76; background-color:transparent; border:1px solid #eff3f7; border-radius:18px;}
.write_form li span.align_right input				{padding-right:36px; text-align:right;}
.write_form li span.align_right > sub				{min-width:24px; color:#8c949e;}
/*.write_form li > span:has(input:focus)			{border-color:#01a368;}*/


/* Write Tab */
.selection_tab										{display:flex; align-items:center; margin-top:12px;}
.selection_tab a									{margin-right:10px; width:100%;}
.selection_tab a:last-child							{margin-right:0;}
.selection_tab a input								{display:none; width:0; height:0;}
.selection_tab a label								{display:flex; justify-content:center; align-items:center; height:44px; font-size:16px; color:#26282c; background-color:#f8f9fb; border-radius:4px;}
.selection_tab a input:checked + label				{font-family:'RobotoM','NotoKrM'; color:#01a368; border:2px solid #01a368; background-color:transparent;}


/* 인증번호 재전송 */
.resend												{position:absolute; top:100%; right:2px; z-index:1; margin-top:12px; padding:3px 0; font-family:'RobotoB','NotoKrB'; font-size:13px; color:#aab0b8;}
.resend:after										{content:""; position:absolute; bottom:0; left:0; z-index:1; width:100%; height:1px; background-color:#aab0b8;}


/* Link Group */
.linkGroup											{display:flex; justify-content:flex-end; align-items:center; margin-bottom:36px; padding:12px 0;}
.linkGroup a										{padding:0 2px; font-size:13px; color:#8c949e;}
.linkGroup a:last-child								{position:relative; margin-left:16px;}
.linkGroup a:last-child:after						{content:""; position:absolute; top:50%; left:-7px; z-index:1; width:1px; height:12px; background-color:#f1f3f5; transform:translateY(-50%);}
.linkGroup .auto_login								{display:flex; align-items:center; margin-right:auto;}
.linkGroup .auto_login label						{margin-left:4px; font-size:13px; color:#26282c;}


/* Activate Button */
.activate_button									{position:relative; display:flex; align-items:center; padding:0 8px; width:40px; height:20px;}
.activate_button:before,
.activate_button:after								{content:""; position:absolute; top:50%; transition:all .1s ease-in;}
.activate_button:before								{left:50%; z-index:0; width:24px; height:14px; background-color:#dde1e4; border-radius:7px; transform:translate(-50%,-50%);}
.activate_button:after								{left:0; z-index:1; width:20px; height:20px; background-color:#f7f7f8; border-radius:50%; box-shadow:0 2px 3px 0 rgba(0,0,0,.1); transform:translateY(-50%);}
.activate_button.on:before							{background-color:#01a368; opacity:.25;}
.activate_button.on:after							{left:20px; background-color:#01a368;}
.activate_button span								{text-indent:-9999px;}


/* 간편 로그인 */
.simple_login										{display:flex; justify-content:space-around; margin-bottom:48px;}
.simple_login a										{width:44px; height:44px; text-indent:-9999px; background-size:100%; border-radius:50%;}
.simple_login a.kakao								{background-image:url('/src/images//icon/logo_kakao.png');}
.simple_login a.naver								{background-image:url('/src/images//icon/logo_naver.png');}
.simple_login a.facebook							{background-image:url('/src/images//icon/logo_facebook.png');}
.simple_login a.google								{background-image:url('/src/images//icon/logo_google.png');}
.simple_login a.apple								{background-image:url('/src/images//icon/logo_apple.png');}
.simple_login a.wechat								{background-image:url('/src/images//icon/logo_wechat.png');}


/* Layer View */
.layerView											{position:fixed; top:0; left:0; z-index:-1; width:100%; height:100%; opacity:0; transition:all .2s;}
.layerView:before									{content:""; position:absolute; top:0; left:0; z-index:1; width:100%; height:100%; background-color:rgba(0,0,0,.4);}
.layerView.on										{opacity:1; z-index:9998;}
.layerView.on .layer_body							{bottom:50%; transform:translateY(50%);}
.layerView.on .bottom_sheet							{bottom:0;}


/* Layer Popup */
.layer_body											{position:absolute; bottom:-100%; left:24px; right:24px; z-index:1; padding:16px; background-color:#fff; border-radius:18px; transition:all .2s;}
.layer_body .popup_container						{padding:16px 8px;}
.layer_body .popup_container > em					{display:block; margin-bottom:20px; font-family:'RobotoB','NotoKrB'; font-size:19px; color:#26282c;}
.layer_body .popup_button							{display:flex;}
.layer_body .popup_button button					{margin-right:10px;}
.layer_body .popup_button button:last-child			{margin-right:0;}
.layer_body .alert_content							{margin-bottom:12px; font-size:14px; color:#484b51; line-height:23px;}


/* Bottom Sheet */
.bottom_sheet										{position:absolute; bottom:-100%; left:0; z-index:1; width:100%; background-color:#fff; border-radius:18px 18px 0 0; transition:all .2s;}
.bottom_sheet > a									{position:fixed; top:0; left:0; z-index:-1; width:100%; height:100%; text-indent:-9999px;}
.bottom_sheet .bs_body								{position:relative; padding:32px 24px;}
.bottom_sheet .bs_body > em							{display:block; margin-bottom:32px; font-family:'RobotoB','NotoKrB'; font-size:19px; color:#26282c;}
.bottom_sheet .bs_body > button						{position:absolute; top:30px; right:24px; z-index:1; width:20px; height:20px; text-indent:-9999px; background:url('/src/images//icon/i_reset.png') no-repeat; background-size:100%;}


/* 시간설정 */
.timer_wrap											{position:relative; display:flex;}
.timer_wrap:before,
.timer_wrap:after									{content:""; position:absolute; top:50%; right:33%; z-index:1; width:4px; height:4px; background-color:#26282c; border-radius:50%; transform:translateY(-50%);}
.timer_wrap:before									{margin-top:-5px;}
.timer_wrap:after									{margin-top:5px;}
.timer_wrap ul										{padding:60px 0; width:100%; height:180px; overflow-y:auto;}
.timer_wrap ul:first-child:before					{content:""; position:absolute; top:50%; left:-10px; right:-10px; z-index:-1; height:60px; background-color:#fbfcfd; border-radius:8px; transform:translateY(-50%);}
.timer_wrap ul:first-child li						{font-size:20px;}
.timer_wrap ul li									{display:flex; justify-content:center; align-items:center; height:60px; font-family:'RobotoB','NotoKrB'; font-size:28px; color:#ebeef0;}
.timer_wrap ul li.on								{color:#26282c;}


/* 생년월일 */
.birth_wrap											{position:relative; display:flex;}
.birth_wrap ul										{padding:60px 0; width:100%; height:180px; overflow-y:auto;}
.birth_wrap ul:first-child:before					{content:""; position:absolute; top:50%; left:-10px; right:-10px; z-index:-1; height:60px; background-color:#fbfcfd; border-radius:8px; transform:translateY(-50%);}
.birth_wrap ul li									{display:flex; justify-content:center; align-items:center; height:60px; font-family:'RobotoB','NotoKrB'; font-size:24px; color:#ebeef0;}
.birth_wrap ul li.on								{color:#26282c;}


/* Popup Calendar Date */
.date_selection										{display:flex; justify-content:center; align-items:center; margin-bottom:8px;}
.date_selection em									{margin:0 24px; font-family:'RobotoB','NotoKrM'; font-size:18px; color:#26282c; letter-spacing:0;}
.date_selection button								{position:relative; width:40px; height:40px; text-indent:-9999px; border:1px solid #ebeef0; background-color:transparent; border-radius:50%;}
.date_selection button:before,
.date_selection button:after						{content:""; position:absolute; top:50%; z-index:1; width:2px; height:10px; background-color:#26282c;}
.date_selection button:before						{margin-top:-8px;}
.date_selection button:after						{margin-top:-2px;}
.date_selection button.prev:before,
.date_selection button.prev:after					{left:50%; margin-left:-2px;}
.date_selection button.prev:before					{transform:rotate(45deg);}
.date_selection button.prev:after					{transform:rotate(-45deg);}
.date_selection button.next:before,
.date_selection button.next:after					{right:50%; margin-right:-2px;}
.date_selection button.next:before					{transform:rotate(-45deg);}
.date_selection button.next:after					{transform:rotate(45deg);}


/* Table Calendar */
.table_calendar table								{width:100%;}
.table_calendar table th,
.table_calendar table td							{text-align:center;}
.table_calendar table th							{height:40px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#aab0b8;}
.table_calendar table th:first-child,
.table_calendar table td:first-child				{color:#ff8094;}
.table_calendar table td							{position:relative; z-index:1; height:48px; font-size:16px; color:#484b51;}
.table_calendar table td.today						{color:#01a368;}
.table_calendar table td.on,
.table_calendar table td:active						{color:#fff;}
.table_calendar table td.on:before,
.table_calendar table td:active:before				{content:""; position:absolute; top:50%; left:50%; z-index:-1; width:36px; height:36px; background-color:#01a368; transform:translate(-50%,-50%); border-radius:50%;}
.table_calendar table td.disable:active:before		{background-color:transparent;}


/* 검색조건 */
.search_filter li									{display:flex; flex-direction:column; margin-bottom:24px;}
.search_filter li:last-child						{margin-bottom:8px;}
.search_filter li em								{margin-bottom:16px; font-size:16px; color:#26282c;}
.search_filter .filter_item							{display:flex; flex-wrap:wrap;}
.search_filter .filter_item a						{margin-right:8px; margin-bottom:8px;}
.search_filter .filter_item a:last-child			{margin-right:0;}
.search_filter .filter_item a input					{display:none; width:0; height:0;}
.search_filter .filter_item a label					{display:flex; align-items:center; padding:0 8px; height:28px; font-size:13px; color:#696e76; border:2px solid #c6cbd0; border-radius:5px;}
.search_filter .filter_item a input:checked + label	{color:#fff; border-color:#01a368; background-color:#01a368;}


/* List Image */
.list_image a										{position:relative; display:flex; flex-direction:column; margin-bottom:10px; height:50px;}
.list_image a:before								{content:""; position:absolute; top:0; bottom:0; left:60px; right:60px; z-index:-1; background-color:transparent; border-radius:12px; transition:all .15s ease-out;}
.list_image a label									{position:relative; display:flex; align-items:center; padding-left:36px; width:100%; height:100%; font-size:16px; color:#696e76;}
.list_image a label:after							{content:""; position:absolute; top:50%; right:16px; z-index:1; width:18px; height:18px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-size:auto 100%; background-position-x:right; opacity:0; transform:translateY(-50%);}
.list_image a p										{position:absolute; top:50%; left:0; z-index:1; width:24px; height:24px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%; transform:translateY(-50%);}
.list_image a p:after								{content:""; position:absolute; top:0; left:0; z-index:1; width:100%; height:100%; border:1px solid rgba(0,0,0,.05); border-radius:50%;}
.list_image a label,
.list_image a label:after,
.list_image a p										{transition:all .2s;}
.list_image a input									{width:0; height:0;}
.list_image a input:checked + label:after			{opacity:1;}
.list_image a:hover:before							{left:0; right:0; background-color:#f1f3f5;}
.list_image a:hover label							{text-indent:8px;}
.list_image a:hover label:after						{right:24px;}
.list_image a:hover p								{left:8px;}


/* List Selection */
.list_selection a									{position:relative; display:flex; flex-direction:column; margin-bottom:10px; height:50px;}
.list_selection a:before							{content:""; position:absolute; top:0; bottom:0; left:60px; right:60px; z-index:-1; background-color:transparent; border-radius:12px; transition:all .15s ease-out;}
.list_selection a label								{position:relative; display:flex; justify-content:center; align-items:center; text-align:center; padding:0 48px; width:100%; height:100%; font-size:16px; color:#484b51;}
.list_selection a label:after						{content:""; position:absolute; top:50%; right:16px; z-index:1; width:18px; height:18px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-size:auto 100%; background-position-x:right; opacity:0; transform:translateY(-50%);}
.list_selection a label,
.list_selection a label:after						{transition:all .2s;}
.list_selection a input								{width:0; height:0;}
.list_selection a input:checked + label,
.list_selection a[data-checked='true'] input + label{color:#01a368;}
.list_selection a input:checked + label:after,
.list_selection a[data-checked='true'] input + label:after{opacity:1;}
.list_selection a:hover:before						{left:0; right:0; background-color:#f1f3f5;}
.list_selection a:hover label:after					{right:24px;}


/* List Country */
.list_country a										{position:relative; display:flex; flex-direction:column; margin-bottom:5px;}
.list_country a input								{width:0; height:0;}
.list_country a input:checked + label:before		{border-color:#01a368;}
.list_country a input:checked + label:after			{left:6px; width:10px; height:10px; background-color:#01a368;}
.list_country a label								{position:relative; display:flex; align-items:center; padding-left:34px; width:100%; height:40px; color:#26282c;}
.list_country a label:before,
.list_country a label:after							{content:""; position:absolute; top:50%; z-index:1; border-radius:50%; transform:translateY(-50%);}
.list_country a label:before						{left:0; width:22px; height:22px; border:2px solid #e5e8eb; transition-duration:.3s;}
.list_country a label:after							{left:8px; width:6px; height:6px; background-color:#f9f9f9; transition:all .1s;}
.list_country a label p								{flex:0 0 30px; margin-right:10px; height:20px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; border:1px solid #f2f4f6;}
.list_country a label strong						{font-size:16px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
.list_country a label span							{flex:none; margin-left:auto; font-family:'RobotoB'; font-size:14px;}


/* 매장정보 공유하기 */
.store_share										{display:flex; flex-wrap:wrap; margin-bottom:-24px;}
.store_share a										{display:flex; flex-direction:column; margin-bottom:24px; width:33%; text-align:center;}
.store_share a:nth-child(3n+2)						{width:34%;}
.store_share a i									{margin:0 auto; width:46px; height:46px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:100%; border-radius:50%;}
.store_share a i.message							{background-image:url('/src/images//icon/logo_message.png');}
.store_share a i.kakaotalk							{background-image:url('/src/images//icon/logo_kakao.png');}
.store_share a i.instagram							{background-image:url('/src/images//icon/logo_instagram.png');}
.store_share a i.facebook							{background-image:url('/src/images//icon/logo_facebook.png');}
.store_share a i.wechat								{background-image:url('/src/images//icon/logo_wechat.png');}
.store_share a i.more								{border:1px solid #f1f1f1; background-image:url('/src/images//icon/logo_more.png');}
.store_share a i.link								{background-color:#f1f3f5; background-image:url('/src/images//icon/logo_link.png');}
.store_share a span									{margin-top:10px; color:#484b51;}


/* 종합 코스랭킹 */
.score_rank											{display:flex; justify-content:space-between; align-items:center; margin-bottom:16px;}
.score_rank .nickname								{display:flex; align-items:center; padding-right:24px; width:100%;}
.score_rank .nickname p								{flex:0 0 40px; height:40px; text-indent:-9999px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.score_rank .nickname em							{padding-left:8px; font-family:'RobotoM','NotoKrM'; font-size:15px; color:#26282c;}
.score_rank.new .nickname i						{flex:0 0 20px; height:20px; margin-left:8px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:20%;}
.score_rank .chart									{flex:0 0 120px; display:flex;}
.score_rank .chart span								{display:flex; flex-direction:column; width:100%; text-align:center;}
.score_rank .chart span b							{margin-bottom:12px; font-size:11px; color:#696e76;}
.score_rank .chart span strong						{font-family:'RobotoM','NotoKrM'; font-size:18px; color:#01a368;}
.score_rank .chart span:first-child strong			{color:#26282c;}


/* 대회 순위 아이콘 */
.rank0,
.rank1,
.rank2,
.rank3												{position:relative; text-indent:-9999px;}
.rank0:after,
.rank1:after,
.rank2:after,
.rank3:after										{content:""; position:absolute; top:50%; left:50%; width:30px; height:30px; background:url('/src/images//icon/rank_badge.png') no-repeat; background-size:auto 100%; transform:translate(-50%,-50%);}
.rank0:after,
.rank1:after										{background-position-x:0;}
.rank2:after										{background-position-x:-30px;}
.rank3:after										{background-position-x:-60px;}


/* Score Table */
table.score_chart									{margin-bottom:4px; width:100%; border:1px solid #dce1e5; table-layout:fixed;}
table.score_chart:last-child						{margin-bottom:0;}
table.score_chart tr.standard						{background-color:#fbfdff;}
table.score_chart tr.standard td					{color:#7b8a97;}
table.score_chart th,
table.score_chart td								{padding:8px 4px; border:1px solid #dce1e5; text-align:center;}
table.score_chart th								{font-size:11px; color:#aab0b8; background-color:#f6f8fa;}
table.score_chart td								{position:relative; font-size:12px; color:#484b51;}
table.score_chart td b								{font-family:'RobotoB','NotoKrB'; color:#26282c;}
table.score_chart td b.birdie						{color:#f99e9e;}
table.score_chart td b.birdie_above					{color:#f56060;}
table.score_chart td b.bogey						{color:#b5d9ff;}
table.score_chart td b.bogey_above					{color:#73b5fa;}
table.score_chart td strong							{display:block; padding:0 2px; text-align:left; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
table.score_chart td i								{font-size:14px;}
table.score_chart td.active:after					{content:""; position:absolute; top:-1px; bottom:-1px; left:-1px; right:-1px; z-index:1; border:3px solid #01a368;}
table.score_chart .hide								{position:relative; background-color:#ebeef0;}
table.score_chart .hide:after						{content:""; position:absolute; top:8px; left:50%; z-index:0; margin-left:-1px; width:16px; height:1px; background-color:#facc56; transform:rotate(-35deg) translateX(-50%);}
table.score_chart .section							{background-color:#ebf6e7;}
table.score_chart th.newperio,
table.score_chart td.newperio,
table.score_chart tr.standard td.newperio			{color:rgba(0,0,0,0.5); background-color:#aaa;}
table.score_chart td.newperio b						{color:rgba(0,0,0,0.5);}
table.score_chart td.handicap						{text-align:left;}
table.score_chart td.handicap .info					{display:flex;gap:15px;}


/* 본인인증방법 */
.certify_tab										{display:flex; margin-top:24px;}
.certify_tab a										{display:flex; align-items:center; margin-right:10px; padding:0 16px; height:36px; font-family:'RobotoB','NotoKrB'; font-size:12px; color:#696e76; background-color:#f5f5f5; border-radius:18px; transition:all .15s;}
.certify_tab a.active								{color:#fff; background-color:#01a368;}


/* 안내문구 */
.guidance											{margin:28px 0 36px; padding:18px 16px; background-color:#f1f3f5; border-radius:10px;}
.guidance span										{display:block; position:relative; padding-left:24px; font-family:'RobotoM','NotoKrM'; font-size:12px; color:#26282c; line-height:16px;}
.guidance span:before,
.guidance span:after								{content:""; position:absolute; z-index:1;}
.guidance span:before								{top:-1px; left:0; width:16px; height:16px; background-color:#aab0b8; border-radius:50%;}
.guidance span:after								{top:2px; left:7px; width:2px; height:9px; background-image:url('/src/images//icon/i_emphasis.png'); background-repeat:no-repeat; background-position:left top; background-size:auto 100%;}


/* 아이디 찾기 결과 */
.find_id											{position:relative; display:flex; flex-direction:column; margin-top:100px; padding-top:82px;}
.find_id:before										{content:""; position:absolute; top:0; left:50%; z-index:1; width:60px; height:60px; background:url('/src/images//icon/result_icon.png') no-repeat center; background-size:24px auto; border-radius:50%; box-shadow:0 4px 16px 0 rgba(0,0,0,.07); transform:translateX(-50%);}
.find_id em											{font-family:'RobotoB','NotoKrB'; font-size:20px; color:#26282c; line-height:32px; text-align:center;}
.find_id dl											{display:flex; justify-content:space-between; align-items:center; margin-top:36px; padding:24px 20px; letter-spacing:0; background-color:#f2f4f6; border-radius:8px;}
.find_id dl dt										{font-size:14px; color:#26282c;}
.find_id dl dd										{flex:none; padding-left:8px; font-size:12px; color:#696e76;}


/* 비밀번호 찾기 바로가기 */
.password_link										{position:fixed; bottom:100px; left:0; z-index:10; display:flex; justify-content:center; align-items:center; width:100%; font-family:'RobotoM','NotoKrM'; font-size:14px;}
.password_link span									{margin-right:12px; color:#060b11;}
.password_link a									{color:#01a368;}


/* 표시영역 */
.display_text										{margin-top:10px;}
.display_text b										{display:block; position:relative; padding-left:24px; font-size:13px;}
.display_text b:before,
.display_text b:after								{content:""; position:absolute; z-index:1;}
.display_text b:before								{top:-1px; left:2px; width:16px; height:16px; border-radius:50%;}
.display_text b:after								{top:2px; left:9px; width:2px; height:9px; background-image:url('/src/images//icon/i_emphasis.png'); background-repeat:no-repeat; background-size:auto 100%;}
.display_text b.basic								{color:#aab0b8;}
.display_text b.basic:before						{background-color:#ebeef0;}
.display_text b.basic:after							{background-position-x:-2px;}
.display_text b.success								{color:#01a368;}
.display_text b.success:before						{background-color:#01a368;}
.display_text b.success:after						{background-position-x:-4px;}
.display_text b.error								{color:#ff8094;}
.display_text b.error:before						{background-color:#ff8094;}
.display_text b.error:after							{background-position-x:0;}
.display_text b.error.password_notFind				{font-size:14px;}
.display_text b.error > i							{display:block;line-height:1.25}

/* 회원가입단계 */
.process											{display:flex; flex-direction:column; margin:16px 0 36px; padding:0 2px;}
.process span										{margin-bottom:20px; font-size:17px; color:#8c949e; letter-spacing:4px;}
.process span b										{font-family:'RobotoB'; color:#26282c;}
.process em											{font-family:'RobotoB','NotoKrB'; font-size:28px; color:#26282c;}


/* 약관동의 */
/* .terms												{padding-top:16px;} */
.agreement > a										{display:flex;}
.agreement > a label								{position:relative; display:flex; align-items:center; padding-left:54px; width:100%; height:60px; font-family:'NotoKrM'; font-size:18px; color:#333d4b; background-color:#f2f4f6; border-radius:16px; transition:all .1s;}
.agreement > a label:before							{content:""; position:absolute; top:50%; left:24px; z-index:1; width:20px; height:20px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-position:-20px top; background-size:auto 20px; transform:translateY(-50%);}
.agreement > a input								{width:0; height:0;}
.agreement > a input:checked + label				{background-color:#e5e8eb;}
.agreement > a input:checked + label:before			{background-position:right top;}
.agreement ul										{margin-top:32px;}
.agreement ul li									{display:flex; align-items:center; width:100%; height:36px;}
.agreement ul li input								{flex:0 0 20px; height:20px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-position:left top; background-size:auto 20px;}
.agreement ul li input:checked						{background-position:right top;}
.agreement ul li a									{position:relative; display:flex; align-items:center; padding:0 8px; padding-right: 20px; width:100%; font-size:14px; color:#4e5968;}
.agreement ul li a:before,
.agreement ul li a:after							{content:""; position:absolute; top:50%; right:5px; z-index:1; width:2px; height:8px; background-color:#c6cbd0; border-radius:1px;}
.agreement ul li a:before							{margin-top:-7px; transform:rotate(-45deg);}
.agreement ul li a:after							{margin-top:-2.5px; transform:rotate(45deg);}


/* 약관보기 */
.terms												{padding-top:16px;}
.terms > em											{display:block; margin-bottom:20px; font-family:'RobotoB','NotoKrB'; font-size:18px; color:#26282c;}
.terms > span										{display:block; margin-bottom:16px; font-family:'RobotoL','NotoKrL'; font-size:12px; color:#26282c; line-height:17px;}
.terms dl											{padding-bottom:24px;}
.terms dl *											{line-height:1.4;}
.terms dl dt										{margin-bottom:8px; font-family:'RobotoM','NotoKrM'; font-size:14px; color:#26282c;}
.terms dl dd										{margin-bottom:4px; font-family:'RobotoL','NotoKrL'; font-size:12px; color:#4e5968;}
.terms dl dd ol li									{position:relative; padding:2px 0 2px 12px; color:#8c949e;}
.terms dl dd ol li:before							{content:""; position:absolute; top:8px; left:5px; z-index:1; width:3px; height:3px; background-color:#696e76; border-radius:50%;}


/* 상세정보안내 */
.subscribe											{position:fixed; top:40%; left:0; z-index:1; display:flex; flex-direction:column; padding-top:84px; width:100%; text-align:center; transform:translateY(-50%);}
.subscribe:before									{content:""; position:absolute; top:0; left:50%; z-index:1; width:160px; height:34px; background:url('/src/images//top/logo.png') no-repeat center; background-size:100%; transform:translateX(-50%);}
.subscribe em										{margin-bottom:16px; font-family:'RobotoB','NotoKrB'; font-size:24px; color:#26282c; line-height:32px;}
.subscribe span										{font-size:14px; color:#696e76; line-height:24px;}


/* 설정하기 */
.setting											{padding:12px 24px 0; border-bottom:1px solid #eeefef; background-color:#fff;}
.setting strong										{display:block; padding:20px 0; font-family:'RobotoB','NotoKrB'; font-size:12px; color:#c6cbd0;}
.setting ul											{padding-bottom:12px; border-bottom:1px solid #f2f3f5;}
.setting ul:last-child								{border-bottom:none;}
.setting ul li,
.setting ul li > a									{display:flex; justify-content:space-between; align-items:center; color:#26282c;}
.setting ul li										{height:54px;}
.setting ul li > a									{position:relative; width:100%; height:100%;}
.setting ul li > a:before,
.setting ul li > a:after							{content:""; position:absolute; top:50%; right:5px; z-index:1; width:2px; height:8px; background-color:#c6cbd0; border-radius:1px;}
.setting ul li > a:before							{margin-top:-7px; transform:rotate(-45deg);}
.setting ul li > a:after							{margin-top:-2.5px; transform:rotate(45deg);}
.setting ul li > a > span							{margin-right:24px; font-size:13px; color:#01a368;}
.setting ul li em									{font-size:16px;}
.setting ul li small								{font-family:'RobotoL','NotoKrL'; font-size:14px;}
.setting ul li button								{height:40px; font-size:16px; color:#26282c; background-color:transparent;}
.setting ul li.timer								{height:0; transition:all .15s; overflow:hidden;}
.setting ul li.timer.on								{height:54px;}


/* 내 프로필 */
.myinfo												{display:flex; justify-content:center; margin-bottom:40px; padding-top:16px;}
.myinfo .edit_profile								{position:relative;}
.myinfo .edit_profile input,
.myinfo .edit_profile p								{width:80px; height:80px;}
.myinfo .edit_profile input							{position:absolute; top:0; left:50%; z-index:1; transform:translateX(-50%); opacity:0;}
.myinfo .edit_profile p								{position:relative; text-indent:-9999px; border:1px solid #c6cbd0; background-color:#f1f3f5; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.myinfo .edit_profile p:before						{content:""; position:absolute; bottom:-4px; right:-4px; width:24px; height:24px; border:3px solid #fff; background-color:#c6cbd0; border-radius:50%;}
.myinfo .edit_profile p:after						{content:""; position:absolute; bottom:3px; right:3px; width:10px; height:10px; background:url('/src/images//icon/picture_i.png') no-repeat; background-size:100%;}
.myinfo .edit_profile a								{position:absolute; top:-9px; right:-13px; z-index:2; width:33px; height:33px; text-indent:-9999px; border:3px solid #fff; background-color:#f1f3f5; border-radius:50%;}
.myinfo .edit_profile a:before,
.myinfo .edit_profile a:after						{content:""; position:absolute; top:6px; left:50%; z-index:1; margin-left:-1px; width:1px; height:15px; background-color:#26282c;}
.myinfo .edit_profile a:before						{transform:rotate(45deg);}
.myinfo .edit_profile a:after						{transform:rotate(-45deg);}


/* 내 정보보기 */
.revise												{margin-top:32px;}
.revise .grouping									{display:flex; justify-content:space-between; align-items:center; margin-bottom:16px;}
.revise .grouping strong							{font-family:'RobotoB','NotoKrB'; font-size:18px; color:#26282c;}
.revise .grouping button							{padding:0 16px; height:30px; font-family:'RobotoB','NotoKrB'; font-size:13px; color:#696e76; border:1px solid #c6cbd0; background-color:transparent; border-radius:4px;}
.revise ul											{margin-bottom:32px;}
.revise ul li										{display:flex; justify-content:space-between; padding:4px 0;}
.revise ul li em,
.revise ul li a,
.revise ul li span									{font-size:14px; color:#26282c; line-height:23px;}
.revise ul li em									{flex:none; padding-right:24px; color:#8c949e;}
.revise ul li span									{padding-right:4px;}
.revise ul li a										{position:relative; padding-right:20px; text-align:right;}
.revise ul li:not(.nocontent) a:before,
.revise ul li:not(.nocontent) a:after								{content:""; position:absolute; top:50%; right:5px; z-index:1; width:1px; height:8px; background-color:#c6cbd0;}
.revise ul li:not(.nocontent) a:before {margin-top:-7px; transform:rotate(-45deg);}
.revise ul li:not(.nocontent) a:after								{margin-top:-2px; transform:rotate(45deg);}


/* 알림 설정 */
.notify												{margin-top:12px;}
.notify li											{display:flex; flex-direction:column; margin-bottom:4px; padding:16px 0; border-bottom:1px solid #f2f3f5;}
.notify li span										{margin-bottom:8px; display:flex; justify-content:space-between; align-items:center; font-size:13px; color:#8c949e; line-height:19px;}
.notify li span em									{font-family:'RobotoM','NotoKrM'; font-size:16px; color:#26282c;}


/* Selectbox Top Search */
label.filter_index									{position:relative; display:flex; align-items:center; padding-right:24px; height:40px; font-family:'RobotoB','NotoKrB'; font-size:14px; color:#26282c;}
label.filter_index:before,
label.filter_index:after							{content:""; position:absolute; top:50%; right:10px; margin-top:-1px; width:2px; height:8px; background-color:#b2b2b2; transition:.15s;}
label.filter_index:before							{margin-right:-1px; transform:rotate(-45deg) translateY(-50%);}
label.filter_index:after							{transform:rotate(45deg) translateY(-50%);}
label.filter_index:hover:before,
label.filter_index:hover:after						{margin-top:-3px;}
label.filter_index:hover:before						{margin-right:-3px; transform:rotate(135deg);}
label.filter_index:hover:after						{margin-right:2px; transform:rotate(-135deg);}


/* Search Form */
.search_form										{position:relative; z-index:1; display:flex; align-items:center; padding:12px 28px; background-color:#fff; box-shadow:0 1px 0 0 rgba(105,110,118,.1);}
.search_form:before									{content:""; position:absolute; top:50%; left:16px; right:16px; z-index:0; height:40px; background-color:#f1f3f5; border-radius:20px; transform:translateY(-50%);}
.search_form.fixed									{position:fixed; top:48px; left:0; z-index:10; width:100%;}
.search_form a,
.search_form input,
.search_form button									{z-index:1;}
.search_form a										{flex:0 0 16px; height:16px; text-indent:-9999px; background:url('/src/images//icon/i_search_form.png') no-repeat; background-size:100%;}
.search_form input									{padding:0 8px; height:40px; font-family:'RobotoR','NotoKrR'; font-size:14px;}
.search_form input::-webkit-input-placeholder		{color:#aab0b8;}
.search_form input:-ms-input-placeholder			{color:#aab0b8;}
.search_form button									{flex:0 0 24px; height:24px; text-indent:-9999px; background:url('/src/images//icon/i_condition.png') no-repeat; background-size:100%;}
.search_form label									{position:relative; flex:0 0 40px; margin-left:16px; height:40px; text-indent:-9999px; background-color:#01a368; border-radius:50%;}
.search_form label:after							{content:""; position:absolute; top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/i_storelist.png') no-repeat; background-size:auto 100%; transform:translate(-50%,-50%);}
.search_form label.convert:after					{background-position-x:-24px;}
.search_form.transform								{padding-right:16px;}
.search_form.transform:before						{right:112px; }
.search_form.transform input						{padding-right:32px;}


/* Search Standard */
.search_standard									{position:relative; z-index:1; display:flex; align-items:center; padding:5px 6px; background-color:#fff; box-shadow:0 1px 0 0 rgba(105,110,118,.1);}
.search_standard.fixed								{position:fixed; top:48px; left:0; z-index:10; width:100%;}
.search_standard > span								{padding:0 10px; width:100%;}
.search_standard > a								{flex:none; padding:0 10px;}
.search_standard > a input							{display:none; width:0; height:0;}
.search_standard > a label							{position:relative; display:flex; align-items:center; padding-left:16px; height:40px; font-size:13px; color:#e5e8eb;}
.search_standard > a label:after					{content:""; position:absolute; top:50%; left:0; z-index:1; width:12px; height:12px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.search_standard > a input:checked + label			{color:#01a368;}
.search_standard > a input:checked + label:after	{background-position-x:right;}
.search_standard .direct_check						{flex:none; display:flex; padding:0 10px;}
.search_standard .direct_check a					{position:relative; margin-left:20px;}
.search_standard .direct_check a:after				{content:""; position:absolute; top:50%; left:-10px; width:1px; height:10px; background-color:#f1f3f5; transform:translateY(-50%);}
.search_standard .direct_check a:first-child:after	{display:none;}
.search_standard .direct_check a label				{position:relative; display:flex; align-items:center; height:40px; font-size:14px; color:#c6cbd0; transition:.1s;}
.search_standard .direct_check a input				{display:none; width:0; height:0;}
.search_standard .direct_check a input:checked + label{color:#01a368;}


/* 내 소식 */
.push_list											{padding:16px;}
.push_list,
.push_list ul										{display:flex; flex-direction:column-reverse;}
.push_list ul li									{display:flex; flex-direction:column; margin-bottom:16px; padding:20px; background-color:#fff; border-radius:16px;}
.push_list ul li:first-child						{margin-bottom:0;}
.push_list ul li.nonconfirmed						{background-color:rgba(255,255,255,.5);}
.push_list ul li .sending							{display:flex; justify-content:space-between; align-items:center; margin-bottom:8px; font-size:13px; color:#8c949e;}
.push_list ul li .sending i							{position:relative; padding-left:22px;}
.push_list ul li .sending i:after					{content:""; position:absolute; top:50%; left:0; width:16px; height:16px; background:url('/src/images//icon/sender_icon.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.push_list ul li .sending i.round:after				{background-position-x:0;}
.push_list ul li .sending i.reserve:after			{background-position-x:-16px;}
.push_list ul li .sending i.event:after				{background-position-x:-32px;}
.push_list ul li .sending i.friend:after			{background-position-x:-48px;}
.push_list ul li .sending i.notice:after			{background-position-x:-64px;}
.push_list ul li .sending i.match:after				{background-position-x:-80px;}
.push_list ul li .sending i.store:after				{background-position-x:-96px;}
.push_list ul li .sending b							{font-family:'RobotoL','NotoKrL';}
.push_list ul li span								{padding:0 8px 0 20px; font-size:14px; color:#26282c; line-height:22px;}
.push_list em										{display:flex; justify-content:center; padding:20px 0 16px; font-size:12px; color:#aab0b8;}


/* Search Form */
.search_curious										{position:relative; padding-top:16px; background-color:#fff;}
.search_curious:after								{content:""; position:absolute; top:100%; left:24px; right:62px; height:1px; background-color:#ebeef0;}
.search_curious.fixed								{position:fixed; top:48px; left:0; z-index:10; width:100%;}
.search_curious.fixed:after							{left:0; right:0;}
.search_curious.fixed span							{padding:0 16px;}
.search_curious:after,
.search_curious span								{transition:all .2s ease-out;}
.search_curious span								{position:relative; display:flex; justify-content:space-between; align-items:center; padding:0 24px;}
.search_curious span input							{padding-right:28px; height:48px; font-family:'RobotoB','NotoKrB'; font-size:22px;}
.search_curious span input::-webkit-input-placeholder{font-family:'RobotoB','NotoKrB'; color:#c6cbd0;}
.search_curious span input:-ms-input-placeholder	{font-family:'RobotoB','NotoKrB'; color:#c6cbd0;}
.search_curious span a								{position:absolute; top:50%; right:64px; width:18px; height:18px; text-indent:-9999px; background-color:#4e5151; border-radius:50%; transform:translateY(-50%);}
.search_curious span a:after						{content:""; position:absolute; top:50%; left:50%; z-index:1; width:8px; height:8px; background-image:url('/src/images//icon/txt_delete.png'); background-size:100%; transform:translate(-50%,-50%);}
.search_curious span button							{flex:0 0 20px; margin-left:18px; height:20px; text-indent:-9999px; background:url('/src/images//icon/i_search_curious.png') no-repeat; background-size:100%;}


/* 상담방식 */
.counsel_btn										{display:flex; margin-top:40px;}
.counsel_btn a										{position:relative; display:flex; justify-content:center; margin-right:16px; padding-top:80px; width:100%; height:120px; font-family:'RobotoB','NotoKrB'; font-size:16px; color:#484b51; background-color:#f1f3f5; border-radius:22px;}
.counsel_btn a:last-child							{margin-right:0;}
.counsel_btn a:before								{content:""; position:absolute; top:24px; left:50%; width:40px; height:40px; background:url('/src/images//icon/i_counsel.png') no-repeat; background-size:auto 100%; transform:translateX(-50%);}
.counsel_btn a.chatting:before						{background-position-x:0;}
.counsel_btn a.telephone:before						{background-position-x:-40px;}


/* FAQ */
.faq												{margin-top:48px;}
.faq em												{display:block; margin-bottom:12px; font-family:'RobotoB','NotoKrB'; font-size:18px; color:#26282c;}
.faq ul												{display:flex; flex-direction:column-reverse;}
.faq ul li a										{display:block; position:relative; padding:20px 32px 20px 0; font-size:14px; color:#26282c; line-height:22px; border-bottom:1px solid #f4f6f8;}
.faq ul li a:before,
.faq ul li a:after									{content:""; position:absolute; top:50%; right:6px; margin-top:-1px; width:2px; height:8px; background-color:#aab0b8; transition:.15s;}
.faq ul li a:before									{margin-right:-1px; transform:rotate(-45deg) translateY(-50%);}
.faq ul li a:after									{transform:rotate(45deg) translateY(-50%);}
.faq ul li a.on										{font-family:'RobotoM','NotoKrM'; border-bottom:none;}
.faq ul li a.on:before,
.faq ul li a.on:after								{margin-top:-3px;}
.faq ul li a.on:before								{margin-right:-3px; transform:rotate(135deg);}
.faq ul li a.on:after								{margin-right:2px; transform:rotate(-135deg);}
.faq ul li p										{display:none; margin-bottom:8px; padding:16px 18px; font-size:13px; color:#484b51; line-height:21px; background-color:#f4f6f8; border-radius:16px;}


/* No Data */
.nodata												{padding:140px 0; text-align:center;}
.nodata p											{margin:0 auto; max-width:280px;}
.nodata strong										{display:block; margin:16px 0 12px; font-size:18px; color:#26282c;}
.nodata span										{font-size:14px; color:#8c949e;}
.store_wrap .nodata									{padding:20px 0 56px;}


/* 공지사항 */
.list_notice										{margin-bottom:8px; padding:0 16px; border-bottom:1px solid #f1f3f5; background-color:#fff;}
.list_notice a										{display:flex; flex-direction:column; padding:20px 0 24px; border-bottom:1px solid #f1f3f5;}
.list_notice a:last-child							{border-bottom:none;}
.list_notice a em									{font-family:'RobotoM','NotoKrM'; font-size:15px; color:#26282c; line-height:21px;}
.list_notice a small								{margin-top:10px; font-size:12px; color:#aab0b8;}


/* 이벤트 */
.list_event											{padding-bottom:48px;}
.list_event a										{position:relative; display:flex; flex-direction:column; margin-bottom:8px; padding:28px 20px; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.list_event a:first-child							{border-top:none;}
.list_event a p										{position:relative; margin-bottom:20px; text-align:center;}
.list_event a em									{margin-bottom:12px; font-family:'RobotoM','NotoKrM'; font-size:16px; color:#26282c;}
.list_event a small									{font-size:14px; color:#8c949e;}
.list_event a.off:after								{content:""; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(255,255,255,.9);}
.list_event a.off p:after							{content:"종료된 이벤트입니다."; position:absolute; top:50%; left:50%; z-index:2; font-family:'RobotoL','NotoKrL'; font-size:14px; color:#26282c; transform:translate(-50%,-50%);}


/* 대회 */
.list_match											{margin-bottom:8px; padding:0 16px; border-bottom:1px solid #f1f3f5; background-color:#fff;}
.list_match a										{display:flex; justify-content:space-between; align-items:center; padding:24px 0; color:#26282c; border-bottom:1px solid #f1f3f5;}
.list_match a:last-child							{border-bottom:none;}
.list_match a .game_info							{display:flex; flex-direction:column;}
.list_match a .game_info span						{display:flex; align-items:center;}
.list_match a .game_info strong						{margin-right:8px; font-size:13px;}
.list_match a .game_info i							{padding:0 6px; line-height:18px; font-family:'RobotoB','NotoKrB'; font-size:11px; color:#fff; border-radius:2px; transform:translateY(-1px); letter-spacing:0;}
.list_match a .game_info i.expect					{background-color:#facc56;}
.list_match a .game_info i.ongoing					{background-color:#96e676;}
.list_match a .game_info i.ending					{font-family:'RobotoM','NotoKrM'; color:#8c949e; background-color:#f2f3f4;}
.list_match a .game_info em							{margin:6px 0; font-family:'RobotoM','NotoKrM'; font-size:16px; line-height:21px;}
.list_match a .game_info small						{position:relative; flex:none; padding-right:16px; font-size:12px; color:#8c949e;}
.list_match a .game_info small:after				{content:""; position:absolute; top:50%; right:7px; width:1px; height:10px; background-color:#f1f3f5; transform:translateY(-50%);}
.list_match a .game_info small:last-child			{flex:1 1 auto; padding-right:0;}
.list_match a .game_info small:last-child:after		{display:none;}
.list_match a p										{flex:0 0 50px; margin-left:20px; height:50px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:6px;}


/* 나의 기록실 */
.list_record										{margin-bottom:8px; padding:0 16px; border-bottom:1px solid #f1f3f5; background-color:#fff;}
.list_record a										{display:flex; align-items:center; padding:20px 0; color:#26282c; border-bottom:1px solid #f1f3f5;}
.list_record a:last-child							{border-bottom:none;}
.list_record a p									{flex:0 0 82px; margin-right:16px; height:82px; text-indent:-9999px; border:1px solid #f1f3f5; background-color:#fafafb; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.list_record a .record_info							{display:flex; flex-direction:column;}
.list_record a .record_info span					{display:flex; margin-bottom:8px;}
.list_record a .record_info em						{margin-bottom:5px; font-family:'RobotoB','NotoKrB'; font-size:16px; line-height:18px;}
.list_record a .record_info strong					{margin-bottom:5px; font-size:12px;}
.list_record a .record_info sub						{font-family:'RobotoL','NotoKrL'; font-size:12px;}


/* 샷 정보 */
.list_video											{display:flex; flex-direction:column; margin-bottom:8px; background-color:#fff;}
.list_video > li									{display:flex; flex-direction:column; color:#26282c; border-bottom:1px solid #f1f3f5;}
.list_video > li label								{position:relative; display:flex; align-items:center; padding:20px 46px 20px 16px;}
.list_video > li label:before,
.list_video > li label:after						{content:""; position:absolute; top:50%; right:24px; margin-top:-1px; width:2px; height:10px; background-color:#b2b2b2; transition:.15s;}
.list_video > li label:before						{margin-right:-1px; transform:rotate(-45deg) translateY(-50%);}
.list_video > li label:after						{transform:rotate(45deg) translateY(-50%);}
.list_video > li label .shot_info					{display:flex; flex-direction:column;}
.list_video > li label .shot_info span				{display:flex; margin-bottom:8px;}
.list_video > li label .shot_info em				{margin-bottom:5px; font-family:'RobotoM','NotoKrM';; font-size:16px; line-height:18px;}
.list_video > li label .shot_info strong			{margin-bottom:5px; font-size:12px;}
.list_video > li label .shot_info sub				{font-family:'RobotoL','NotoKrL'; font-size:12px;}
.list_video > li label.on:before,
.list_video > li label.on:after						{margin-top:-4px;}
.list_video > li label.on:before					{margin-right:-4px; transform:rotate(135deg);}
.list_video > li label.on:after						{margin-right:2px; transform:rotate(-135deg);}
.list_video > li label.on + .course_status			{display:block;}
.list_video > li .course_status						{display:none;}
.list_video > li .course_status a					{display:flex; align-items:center; padding:16px; border-bottom:1px solid #fff; background-color:#f1f3f5;}
.list_video > li .course_status a:last-child		{border-bottom:none;}
.list_video > li .course_status a p					{position:relative; flex:0 0 76px; margin-right:16px; height:76px; text-indent:-9999px; background-color:#fafafb; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:12px; overflow:hidden;}
.list_video > li .course_status a p:before,
.list_video > li .course_status a p:after			{content:""; position:absolute;}
.list_video > li .course_status a p:before			{top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.2);}
.list_video > li .course_status a p:after			{top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/thumb_play.png') no-repeat center; background-size:100%; transform:translate(-50%,-50%);}
.list_video > li .course_status a p.empty:before,
.list_video > li .course_status a p.empty:after		{display:none;}
.list_video > li .course_status a aside				{display:flex; flex-direction:column; width:100%;}
.list_video > li .course_status a aside span,
.list_video > li .course_status a aside dl			{display:flex; justify-content:space-between; align-items:center; font-size:12px; color:#26282c;}
.list_video > li .course_status a aside span						{margin:2px 0;}
.list_video > li .course_status a aside span em		{font-size:14px;}
.list_video > li .course_status a aside span em b	{margin-right:4px; font-family:'RobotoB','NotoKrB';}
.list_video > li .course_status a aside span i		{display:flex; align-items:center; padding:0 12px; height:18px; font-size:11px; color:rgba(255,255,255,.8); background-color:#01a368; border-radius:9px; transform:translateY(-1px); letter-spacing:0;}
.list_video > li .course_status a aside span i.good						{background-color:#fce500; color:#000;}
.list_video > li .course_status a aside span i.nice						{background-color:#FF8000;}
.list_video > li .course_status a aside span i.perfect						{background-color:#f00;}
.list_video > li .course_status a aside span i.ceremony						{background-color:#f00;}
.list_video > li .course_status a aside span i.holein						{background-color:#8000FF;}
.list_video > li .course_status a aside > .able-wrap					{flex-wrap:wrap; margin-top:-1px;}
.list_video > li .course_status a aside > .able-wrap i								{margin-left:3px;}
.list_video > li .course_status a aside > .able-wrap i:first-of-type				{margin-left:auto;}
.list_video > li .course_status a aside dl			{margin-top:5px;}
.list_video > li .course_status a aside dl dd		{font-family:'RobotoL','NotoKrL'; font-size:11px; color:#696e76;}
.list_video > li .nodata							{padding:20px 0; background-color:#f1f3f5;}
.list_video > li .nodata p							{margin:0 auto 12px;}
.list_video > li .nodata p > img					{background-color:rgba(0,0,0,.1); border-radius:50%;}
.list_video > li .nodata p + span				{color:rgba(0,0,0,.25);}


/* Score Point */
.score_point										{flex:0 0 60px; display:flex; flex-direction:column; margin-left:auto; padding:0 2px; text-align:right;}
.score_point b										{font-family:'RobotoB'; font-size:24px; color:#26282c;}
.score_point small									{margin-top:2px; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#8c949e;}


/* 게시글 읽기 */
.view_board											{display:flex; flex-direction:column; padding:16px 0;}
.view_board i										{display:flex; justify-content:center; align-items:center; margin-bottom:8px; height:18px; font-family:'RobotoM','NotoKrM'; font-size:11px; border-radius:1px; letter-spacing:0;}
.view_board i.ongoing								{width:48px; color:#fff; background-image:linear-gradient(to right, #26A7E8 0%, #4188F3 100%);}
.view_board i.ending								{width:40px; color:#8c949e; background-color:#ebeef0;}
.view_board em										{font-family:'RobotoB','NotoKrB'; font-size:17px; color:#26282c; line-height:23px;}
.view_board small									{margin-top:8px; font-size:13px; color:#aab0b8;}
.view_board span									{margin-top:24px; padding-top:24px; font-size:15px; color:#484b51; line-height:26px; border-top:1px solid #f1f3f5;}


/* 지도보기 매장 팝업 */
.location											{position:relative; overflow:hidden;}
.location .roadmap									{height:calc(100vh - 156px);}
.marker_point										{position:absolute; bottom:-100%; left:16px; right:16px; z-index:1; background-color:#fff; border-radius:8px; box-shadow:0 4px 12px 0 rgba(0,0,0,.2); transition:all .3s ease-out;}
.marker_point a										{display:flex; align-items:center; padding:16px 24px;}
.marker_point a p									{flex:0 0 80px; position:relative; margin-right:20px; height:80px; text-indent:-9999px; background-color:#fafafb; background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:12px; overflow:hidden;}
.marker_point a p.on:before,
.marker_point a p.on:after							{content:""; position:absolute;}
.marker_point a p.on:before							{top:0; right:0; width:0; height:0; border-style:solid; border-width:0 36px 36px 0; border-color:transparent #01a368 transparent transparent;}
.marker_point a p.on:after							{top:5px; right:5px; width:12px; height:12px; background:url('/src/images//icon/i_favorite.png') no-repeat; background-size:auto 100%;}
.marker_point a .detail_store						{display:flex; flex-wrap:wrap; align-content:space-around; min-height:60px;}
.marker_point a .detail_store i						{position:relative; display:inline-block; margin-bottom:8px; padding:4px 5px 3px 20px; font-size:11px; color:#0b57d0; background-color:#d3e3fd; border-radius:2px;}
.marker_point a .detail_store i:before				{content:""; position:absolute; top:50%; left:4px; width:14px; height:14px; background:url('/src/images//icon/i_membership.png') no-repeat; background-size:100%; transform:translateY(-50%);}
.marker_point a .detail_store em					{display:flex; align-items:center; width:100%; font-family:'RobotoM','NotoKrM'; font-size:16px; color:#26282c; line-height:18px;}
.marker_point a .detail_store em sub,
.marker_point a .detail_store em span				{margin-left:4px; line-height:16px;}
.marker_point a .detail_store em sub				{width:16px; text-indent:-9999px; background:#01a368 url('/src/images//icon/i_bookmark.png') no-repeat center; background-size:10px; border-radius:50%;}
.marker_point a .detail_store em span				{flex:none; font-size:12px; color:#01a368; letter-spacing:0;}
.marker_point a .detail_store address				{display:block; margin-top:6px; width:100%; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#696e76;}
.marker_point a .detail_store .keyword				{margin-top:8px;}
.marker_point a .detail_store .keyword span			{margin-right:5px; font-size:12px; color:#c6cbd0;}
.marker_point.on									{bottom:64px;}
.marker_point .btn_store							{position:absolute; bottom:16px; right:24px;}
.current											{position:absolute; top:16px; right:16px; z-index:1; width:40px; height:40px; text-indent:-9999px; background-color:#fff; border-radius:50%; box-shadow:0 8px 12px 0 rgba(0,0,0,.15);}
.current:after										{content:""; position:absolute; top:50%; left:50%; width:20px; height:20px; background:url('/src/images//icon/i_current.png') no-repeat; background-size:100%; transform:translate(-50%,-50%);}
.test												{position:absolute; bottom:200px; right:16px; z-index:1; display:flex; justify-content:center; align-items:center; width:60px; height:60px; color:#fff; text-align:center; background-color:#ff0000; border-radius:50%;}


/* 대회상세 상단정보 */
.main_info											{position:relative; display:flex; flex-direction:column; padding:60px 20px 28px; color:#fff; background-repeat:no-repeat; background-position:center; background-size:cover;}
.main_info:after									{content:""; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.5);}
.main_info *										{z-index:1;}
.main_info span										{font-size:14px;}
.main_info em										{margin:8px 0; font-family:'RobotoM','NotoKrM'; font-size:20px; line-height:24px;}
.main_info small									{position:relative; margin-right:28px; padding-left:20px; font-size:12px;}
.main_info small:before,
.main_info small:after								{content:""; position:absolute; top:50%; transform:translateY(-50%);}
.main_info small:before								{left:0; width:14px; height:14px; background:url('/src/images//icon/i_match.png') no-repeat; background-size:auto 100%;}
.main_info small:last-child:before					{background-position-x:right;}
.main_info small:after								{right:-18px; width:3px; height:3px; background-color:#fff; border-radius:50%;}
.main_info small:last-child							{margin-right:0;}
.main_info small:last-child:after					{display:none;}
.main_info i										{display:inline-block; margin-top:10px; padding:0 6px; line-height:18px; font-family:'RobotoB','NotoKrB'; font-size:11px; color:#fff; border-radius:2px; letter-spacing:0;}
.main_info i.expect									{background-color:#facc56;}
.main_info i.ongoing								{background-color:#96e676;}
.main_info i.ending									{font-family:'RobotoM','NotoKrM'; color:#8c949e; background-color:#f2f3f4;}


/* Match Tab Button */
.match_tab_button									{display:flex; justify-content:space-between; margin-top:-48px; padding-top:48px; border-bottom:2px solid #f1f3f5;}
.match_tab_button.fixed								{position:fixed; top:48px; left:0; z-index:10; width:100%;}
.match_tab_button button							{position:relative; z-index:1; width:100%; height:38px; font-size:13px; color:#aab0b8; background-color:#fff; transition:all .2s;}
.match_tab_button button:after						{content:""; position:absolute; bottom:-2px; left:50%; z-index:1; width:0; height:2px; background-color:transparent; transform:translateX(-50%); transition:all .4s;}
.match_tab_button button.active						{color:#26282c;}
.match_tab_button button.active:after				{width:100%; background-color:#26282c;}


/* 코스정보 */
.course li											{margin-bottom:20px;}
.course li:last-child								{margin-bottom:0;}
.course li strong									{display:block; margin-bottom:8px; padding-left:18px; font-family:'RobotoM','NotoKrM'; font-size:14px; color:#26282c; background:url('/src/images//icon/i_course.png') no-repeat left center; background-size:14px;}
.course li article									{padding:10px 24px; border:1px solid #ebeef0; border-radius:12px;}
.course li article .level,
.course li article .hole							{display:flex; flex-direction:column;}
.course li article .level span						{display:flex; justify-content:space-between; align-items:center; height:24px;}
.course li article .level span b					{font-size:13px; color:#ff4b76;}
.course li article .level span:last-child b			{color:#1ab467;}
.course li article .level span:last-child i:before	{background-color:#1ab467;}
.course li article .level span i					{position:relative; width:80px; height:16px; text-indent:-9999px; background-color:#ebeef0;}
.course li article .level span i:before,
.course li article .level span i:after				{content:""; position:absolute; top:0; left:0; height:100%;}
.course li article .level span i:before				{background-color:#ff4b76;}
.course li article .level span i:after				{width:100%; background:url('/src/images/icon/mark_bg.png') repeat-x; background-size:auto 100%;}
.course li article .level span i.mark0:before		{width:0;}
.course li article .level span i.mark5:before       {width:8px;}
.course li article .level span i.mark10:before      {width:16px;}
.course li article .level span i.mark15:before      {width:24px;}
.course li article .level span i.mark20:before      {width:32px;}
.course li article .level span i.mark25:before      {width:40px;}
.course li article .level span i.mark30:before      {width:48px;}
.course li article .level span i.mark35:before      {width:56px;}
.course li article .level span i.mark40:before      {width:64px;}
.course li article .level span i.mark45:before      {width:72px;}
.course li article .level span i.mark50:before      {width:80px;}

.course li article .hole							{margin-top:6px; padding-top:8px; border-top:1px dashed #f1f3f5;}
.course li article .hole span						{display:flex; justify-content:space-between; align-items:center; height:24px; color:#26282c;}
.course li article .hole span b						{font-family:'RobotoL','NotoKrL'; font-size:12px;}
.course li article .hole span sup					{display:flex; align-items:center; font-family:'RobotoM','NotoKrM'; font-size:16px; letter-spacing:0;}
.course li article .hole span sup sub				{margin-left:3px; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#8c949e; transform:translateY(1px);}


/* 라운드 설정 정보 */
.round_setup										{display:flex; flex-wrap:wrap; margin:0 -3px -6px;}
.round_setup li										{margin-bottom:6px; padding:0 3px; width:50%;}
.round_setup li.whole								{width:100%;}
.round_setup li dl									{display:flex; justify-content:space-between; align-items:center; padding:0 8px; height:32px; font-size:11px; color:#26282c; background-color:#f7f7f7;}
.round_setup li dl dd,
.round_setup li.whole dl dt							{font-family:'RobotoL','NotoKrL';}
.round_setup li dl dt								{flex:none; font-family:'RobotoM','NotoKrM';}
.round_setup li dl dt b								{padding-left:2px; font-family:'RobotoM','NotoKrM';}
.round_setup .participation							{display:flex; flex-direction:column; padding:8px; font-size:11px; color:#26282c; background-color:#f7f7f7;}
.round_setup .participation strong					{flex:none; margin-bottom:8px; font-family: 'RobotoL', 'NotoKrL'; font-weight:400;}
.round_setup .grade_information						{display:flex; flex-direction:column; border: 1px solid #ebeef0; font-family: 'RobotoL', 'NotoKrL'; color:#26282c;}
.round_setup .grade_information .row				{display:flex;}
.round_setup .grade_information .row + .row			{border-top: 1px solid #ebeef0}
.round_setup .grade_information .row_head			{align-items: center;}
.round_setup .grade_information .row_head > div:first-child	{flex:2;}
.round_setup .grade_information .row_head > div		{flex:1;display:flex;justify-content: center;align-items: center;min-height:32px;}
.round_setup .grade_information .row .attribute 	{display:flex; align-items: center; justify-content: center; flex:1;}
.round_setup .grade_information .row div + div		{border-left: 1px solid #ebeef0}
.round_setup .grade_information .row .icon-column	{display:flex; flex-direction: column;}
.round_setup .grade_information .row .icon-column:last-child	{font-family:'RobotoM','NotoKrM';}
.round_setup .grade_information .icon-column > * 	{width: 100%; min-height:32px; display:flex; align-items: center; justify-content: center;}
.round_setup .grade_information .icon-column > * + * {border-top: 1px solid #ebeef0}
.round_setup .grade_information img					{width: 20px;height: 20px;}


/* Match Filter */
.match_filter										{display:flex; align-items:center; margin-bottom:8px; padding:0 10px; height:50px; border-bottom:1px solid #eeefef; background-color:#fff;}
.match_filter span									{padding:0 10px; width:100%;}
.match_filter span > a								{display:block; position:relative;}
.match_filter span > a:after						{content:""; position:absolute; top:50%; right:0; width:1px; height:20px; border-right:1px dashed #eeefef; transform:translateY(-50%);}
.match_filter span > a input						{display:none; width:0; height:0;}
.match_filter span > a label						{position:relative; display:flex; align-items:center; padding-left:20px; height:40px; font-family:'RobotoB','NotoKrB'; font-size:14px; color:#e5e8eb;}
.match_filter span > a label:after					{content:""; position:absolute; top:50%; left:0; z-index:1; width:12px; height:12px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.match_filter span > a input:checked + label		{color:#01a368;}
.match_filter span > a input:checked + label:after	{background-position-x:right;}


/* 나의 랭킹정보 */
.myranking											{position:sticky; top:48px; z-index:10; display:flex; align-items:center; margin-bottom:8px; padding:16px 20px 16px 0; font-family:'RobotoM','NotoKrM'; color:#01a368; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff; transition:all .15s ease-out;}
.myranking:before									{content:""; position:absolute; top:0; bottom:0; left:60px; right:60px; background-color:transparent; transition:all .15s ease-out;}
.myranking:hover									{padding-left:8px; padding-right:28px; border-color:#ebeef0;}
.myranking:hover:before								{left:0; right:0; background-color:#f1f3f5;}
.myranking *										{z-index:1;}
.myranking strong									{flex:0 0 56px; font-size:18px; text-align:center;}
.myranking .nickname								{display:flex; align-items:center; width:100%;}
.myranking .nickname p								{flex:0 0 30px; height:30px; text-indent:-9999px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.myranking .nickname em								{padding-left:8px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap; font-size:15px; color:#26282c;}
.myranking span										{flex:none; display:flex; align-items:center; justify-content:center; font-size:16px; min-width:55px;}
.myranking span sub									{margin-left:2px; font-family:'RobotoR','NotoKrR'; font-size:12px; transform:translateY(1px);}
.myranking.new .nickname i							{flex:0 0 20px; height:20px; margin-left:8px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:20%;}
.myranking.new.stroke span,
.myranking.new.newperio span						{flex-direction:column; justify-content:center; min-width:55px;}
.myranking.new span	em								{margin-bottom:5px; font-family:'RobotoR','NotoKrR'; font-size:11px; color:#8c949e;}
.myranking.holeinone							{padding-left:20px;padding-right:20px;}
.myranking.new.holeinone span						{flex-direction:column; justify-content:center; min-width:55px; font-size:14px;}
.myranking.new.holeinone .nickname					{flex:2.5; max-width:40vw;}
.myranking.new.holeinone .course					{flex:1.5; display:block; width:55px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; text-align:center;}
.myranking.new.holeinone .hole						{flex:1;}
.myranking.new.holeinone .date						{flex:1.7; min-width:80px;}


/* 대회 랭킹 리스트 */
.ranking_list										{margin-bottom:8px; padding:8px 0; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.ranking_list li:not(.ranking_list_head)			{position:relative; display:flex; align-items:center; padding:8px 20px 8px 0; color:#26282c; transition:all .15s ease-out;}
.ranking_list li:not(.ranking_list_head):before		{content:""; position:absolute; top:2px; bottom:2px; left:60px; right:60px; background-color:transparent; border-radius:12px; transition:all .15s ease-out;}
.ranking_list li:not(.ranking_list_head):hover		{padding-left:8px; padding-right:28px;}
.ranking_list li:not(.ranking_list_head):hover:before	{left:8px; right:8px; background-color:#f1f3f5;}
.ranking_list li:not(.ranking_list_head) *			{z-index:1;}
.ranking_list li:not(.ranking_list_head) strong		{flex:0 0 56px; font-size:18px; text-align:center;}
.ranking_list li:not(.ranking_list_head) .nickname	{display:flex; align-items:center; width:100%;}
.ranking_list li:not(.ranking_list_head) .nickname p	{flex:0 0 30px; height:30px; text-indent:-9999px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.ranking_list li:not(.ranking_list_head) .nickname em	{padding-left:8px; font-size:15px;}
.ranking_list li:not(.ranking_list_head) span		{flex:none; display:flex; justify-content:center; align-items:center; font-size:16px; min-width:55px;}
.ranking_list li:not(.ranking_list_head) span sub	{margin-left:2px; font-size:12px; transform:translateY(1px);}
.ranking_list.new .nickname i						{flex:0 0 20px; height:20px; margin-left:8px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:20%;}
.ranking_list.new.stroke li:not(.ranking_list_head) span,
.ranking_list.new.newperio li:not(.ranking_list_head) span,
.ranking_list.new.holeinone li span					{flex-direction:column; justify-content:center; width:55px;}
.ranking_list.new li span em						{margin-bottom:5px; font-family:'RobotoR','NotoKrR'; font-size:11px; color:#8c949e;}
.ranking_list li .nickname em						{overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.ranking_list.holeinone li							{padding-left:20px;}
.ranking_list.holeinone span						{font-size:14px;}
.ranking_list.new.holeinone span					{font-size:14px;}
.ranking_list.new.holeinone .nickname				{flex:2.5; max-width:40vw;}
.ranking_list.new.holeinone .course					{flex:1.5; display: block; width: 55px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap; text-align: center;}
.ranking_list.new.holeinone .hole					{flex:1;}
.ranking_list.new.holeinone .date					{flex:1.7; min-width:80px;}

.ranking_list_head									{position:sticky; top:122px; z-index:10; display:flex; align-items:center; padding:8px 20px 8px 0; border-bottom:1px solid #f1f3f5; font-size:12px; font-family:'RobotoR','NotoKrR'; color: #8c949e; background-color:#fff;}
.ranking_list_head strong							{flex:0 0 56px; text-align:center; justify-content: center;}
.ranking_list_head .nickname						{display:flex; align-items:center; justify-content: center; width:100%;}
.ranking_list_head span								{flex:none; display:flex; align-items:center; flex-direction: column; justify-content: center; width:55px;}

/* 시상내역 리스트 */
.award												{border-top:1px solid #f1f3f5;}
.award > li											{display:flex; flex-direction:column; margin-bottom:8px; padding:28px 20px 16px; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.award > li > em									{display:block; margin-bottom:12px; padding-left:24px; font-family:'RobotoB','NotoKrB'; font-size:16px; color:#26282c; background:url('/src/images//icon/i_award.png') no-repeat left center; background-size:16px;}
.award > li > .substance							{display:flex; margin-bottom:4px; padding:8px 12px; background-color:#f2f4f6; border-radius:8px;}
.award > li > .substance dl:nth-child(1)			{flex:1 1 0;}
.award > li > .substance dl:nth-child(2)			{flex:2 2 0;}
.award > li > .substance dl:nth-child(3)			{flex:2 2 0;}
.award > li > .substance dl dd,
.award > li > .substance dl dt						{display:flex; justify-content:center; align-items:center; line-height:14px;}
.award > li > .substance dl dd						{padding:5px 0; font-size:11px; color:#aab0b8; border-bottom:1px solid #e5e8eb;}
.award > li > .substance dl dt						{padding:8px 3px; font-family:'RobotoM','NotoKrM'; font-size:12px; color:#4e5968;}


/* 시상내역 입상자 */
.prize_rank											{padding:8px 0;}
.prize_rank li										{position:relative; display:flex; align-items:center; padding-right:4px; height:40px; color:#26282c;}
.prize_rank li strong								{flex:0 0 40px; margin-right:8px; text-align: center;}
.prize_rank li .nickname							{display:flex; align-items:center; width:100%;}
.prize_rank li .nickname p							{flex:0 0 32px; height:32px; text-indent:-9999px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.prize_rank li .nickname em							{padding-left:8px; font-size:14px;}
.prize_rank li span									{flex:none; display:flex; align-items:center; font-family:'RobotoM','NotoKrM'; font-size:15px;}
.prize_rank li span sub								{margin-left:2px; font-family:'RobotoR','NotoKrR'; font-size:12px; transform:translateY(1px);}


/* 매장 대표사진 */
.store_picture										{position:fixed; width:100%; height:170px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover;}
.store_picture:after								{content:""; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.7);}


/* 매장상세 상단 */
.store_detail										{padding-top:150px;}
.store_detail .detail_head							{position:relative; z-index:1; margin-bottom:8px; padding-bottom:40px; border-bottom:1px solid #f1f3f5; background-color:#fff; border-radius:20px 20px 0 0;}
.store_detail .detail_head a						{position:absolute; top:-92px; left:50%; width:130px; height:130px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover; box-shadow:0 4px 12px 0 rgba(0,0,0,.3); transform:translateX(-50%);}
.store_detail .detail_head a:before,
.store_detail .detail_head a:after					{content:""; position:absolute; bottom:6px; right:6px; width:30px; height:30px;}
.store_detail .detail_head a:before					{border:1px solid rgba(255,255,255,.5); background-color:rgba(0,0,0,.2); border-radius:50%;}
.store_detail .detail_head a:after					{background:url('/src/images//icon/picture_full.png') no-repeat; background-size:100%;}
.store_detail .bookmark								{position:absolute; /* top:0; right:0; */ top:15px; right:20px; width:40px; height:40px;}
.store_detail .bookmark input						{display:none; width:0; height:0;}
.store_detail .bookmark label						{display:block; position:relative; width:100%; height:100%; text-indent:-9999px; /* background-color:#f2f2f2; */ background-color:#eee; border-radius:50%;}
.store_detail .bookmark label:after					{content:""; position:absolute; top:50%; left:50%; width:16px; height:16px; background:url('/src/images//icon/i_favorite.png') no-repeat; background-position-x:right; background-size:auto 100%; transform:translate(-50%,-50%);}
.store_detail .bookmark input:checked + label		{background-color:#f9f9f9;}
.store_detail .bookmark input:checked + label:after	{background-position-x:0;}
.store_detail .site_info							{display:flex; flex-direction:column; padding-top:62px;}
.store_detail .site_info .keyword,
.store_detail .site_info em,
.store_detail .site_info .distance,
.store_detail .site_info .reserve					{display:flex; justify-content:center;}
.store_detail .site_info .keyword span				{display:flex; align-items:center; margin:0 4px; padding:0 12px; height:18px; font-family:'RobotoM','NotoKrM'; font-size:11px; border-radius:9px;}
.store_detail .site_info .keyword span.screen		{color:#fff; background-color:#26282c;}
.store_detail .site_info .keyword span.training		{color:#ebf7ee; background-color:#01a368;}
.store_detail .site_info .keyword span.lesson		{color:#01a368; background-color:#ebf7ee;}
.store_detail .site_info em							{margin-top:16px; font-family:'RobotoM','NotoKrM'; font-size:22px; color:#26282c;}
.store_detail .site_info .distance					{margin-top:16px;}
.store_detail .site_info .distance span,
.store_detail .site_info .distance i				{margin:0 6px; font-family:'RobotoL','NotoKrL'; font-size:13px;}
.store_detail .site_info .distance span				{color:#26282c;}
.store_detail .site_info .distance i				{padding-left:12px; color:#01a368; background:url('/src/images//icon/i_location.png') no-repeat left center; background-size:9px 12px;}
.store_detail .site_info .reserve					{margin-top:24px; padding:0 52px;}
.store_detail .site_info .reserve button			{position:relative; margin:0 6px; width:100%; height:40px; font-size:14px; color:#26282c; text-indent:20px; border:1px solid #aab0b8; background-color:transparent; border-radius:20px;}
.store_detail .site_info .reserve button:before		{content:""; position:absolute; top:50%; left:50%; margin-left:-36px; width:12px; height:12px; background:url('/src/images//icon/i_reserve.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.store_detail .site_info .reserve button:last-child:before	{background-position-x:right;}
.store_detail .site_info .reserve.eng button:before				{margin-left:-63px;}


/* 편의시설 및 서비스 */
.service											{display:flex; justify-content:space-around;}
.service i											{position:relative; padding-top:64px; color:#26282c; line-height:15px; text-align:center;}
.service i:before,
.service i:after									{content:""; position:absolute; left:50%; transform:translateX(-50%);}
.service i:before									{top:0; width:56px; height:56px; background-color:#f1f3f5; border-radius:50%;}
.service i:after									{top:10px; width:36px; height:36px; background:url('/src/images//icon/i_service.png') no-repeat; background-size:auto 100%;}
.service i.swing:after								{background-position-x:0;}
.service i.seat:after								{background-position-x:-36px;}
.service i.park:after								{background-position-x:-72px;}
.service i.storage:after							{background-position-x:-108px;}
.service i.prolesson:after							{background-position-x:-144px;}


/* 매장상세 설명 */
.explain *											{font-size:14px; color:#26282c; line-height:24px;}
.explain span										{display:block; text-align:center;}
.explain img										{border-radius:20px;}


/* 매장전경사진 */
.storeslider										{position:relative; text-align:center; border-radius:20px; overflow:hidden;}


/* 매장사진 전체보기 */
.picture_view										{text-align:center;}


/* Store Direction Nav */
.store-direction-nav								{width:0; height:0; text-indent:-9999px;}
.store-direction-nav a								{position:absolute; top:50%; z-index:2; width:24px; height:48px; text-indent:-9999px; background:url('/src/images//icon/btn_direction_nav.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.store-direction-nav .store-prev					{left:0; background-position-x:right;}
.store-direction-nav .store-next					{right:0;}


/* Store Control Nav */
.control_paging										{position:absolute; bottom:10px; left:50%; z-index:2; display:flex; align-items:center; padding:0 8px; height:18px; background-color:rgba(0,0,0,.7); border-radius:9px; transform:translateX(-50%);}
.control_paging span								{color:rgba(255,255,255,.4);}
.store-control-nav li								{padding:0 3px;}
.store-control-paging li a							{display:none; font-size:12px; color:#fff;}
.store-control-paging li a.store-active				{display:block;}


/* 매장위치 */
.location_store										{display:flex; flex-direction:column; padding-bottom:12px;}
.location_store span								{position:relative; margin-bottom:8px; padding-left:26px; font-size:13px; color:#26282c; line-height:18px;}
.location_store span:before							{content:""; position:absolute; top:-1px; left:0; width:20px; height:20px; background:url('/src/images//icon/i_store_location.png') no-repeat; background-size:auto 100%;}
.location_store span:last-child:before				{background-position-x:right;}
.location_store span sub							{font-size:12px; color:#8c949e;}
.location_store span *								{display:block; line-height:19px;}


/* 매장 길찾기 */
.nav_store											{position:relative;}
.nav_store .shortcut								{position:absolute; bottom:10px; left:18px; right:18px; z-index:1; display:flex; height:40px; background-color:rgba(255,255,255,.8); border-radius:4px;}
.nav_store .shortcut a								{position:relative; display:flex; justify-content:center; align-items:center; padding-left:24px; width:100%; font-size:13px; color:#26282c;}
.nav_store .shortcut a:before						{content:""; position:absolute; top:50%; left:50%; margin-left:-34px; width:20px; height:20px; background:url('/src/images//icon/i_nav_store.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.nav_store .shortcut a.copy:before					{background-position-x:0;}
.nav_store .shortcut a.way:before					{margin-left:-28px; background-position-x:-20px;}
.nav_store .shortcut a.share:before					{background-position-x:-40px;}
.nav_store .shortcut.eng a.copy:before				{margin-left:-50px;}
.nav_store .shortcut.eng a.way:before				{margin-left:-49px;}
.nav_store .simplemap								{height:200px; border-radius:20px; overflow:hidden;}
.nav_store .largemap								{height:450px; border-radius:20px; overflow:hidden;}


/* 통계 최고 기록 */
.best_score											{position:relative; display:flex; margin:0 -20px;}
.best_score span									{display:flex; flex-direction:column; margin-bottom:-10px; width:100%; font-family:'RobotoL','NotoKrL'; color:#26282c; text-align:center; border-right:1px dashed #ebeef0;}
.best_score span:last-child							{border-right:none;}
.best_score span b									{margin-bottom:8px; font-size:12px;}
.best_score span strong								{display:flex; justify-content:center; align-items:center; margin-bottom:4px; font-family:'RobotoB','NotoKrB'; font-size:23px; letter-spacing:0;}
.best_score span strong sub							{font-size:16px; transform:translate(1px,1px);}
.best_score span small								{font-size:11px; color:#8c949e; letter-spacing:0;}
.best_score span small q							{font-family:'RobotoR','NotoKrR'; color:#26282c;}
.best_score span.exclusive							{flex-direction:row; justify-content:flex-end; align-items:center; padding:0 32px; height:28px;}
.best_score span.exclusive b,
.best_score span.exclusive strong					{margin-bottom:0;}
.best_score span.exclusive b						{margin-right:auto;}
.best_score span.exclusive small					{margin-right:8px;}


/* 통계 평균 기록 */
.list_average dl									{margin-bottom:24px;}
.list_average dl:last-child							{margin-bottom:0;}
.list_average dl dt									{position:relative; margin-bottom:12px; padding-left:20px; font-family:'RobotoM','NotoKrM'; font-size:14px; color:#26282c; background:url('/src/images//icon/i_average.png') no-repeat left center; background-size:12px;}
.list_average dl dd									{display:flex; justify-content:space-between; align-items:center; margin-bottom:6px; padding:0 16px; height:32px; font-family:'RobotoL','NotoKrL'; color:#26282c; background-color:#f8f9fb;}
.list_average dl dd:last-child						{margin-bottom:0;}
.list_average dl dd sup								{display:flex; align-items:center; font-family:'RobotoM','NotoKrM'; font-size:14px; letter-spacing: 0;}
.list_average dl dd sup sub							{margin-left:3px; font-family:'RobotoL','NotoKrL'; font-size:11px; color:#8c949e; transform:translateY(1px);}


/* 통계 상세 상단영역 */
.statistic_average									{position:relative; display:flex; flex-direction:column; padding:60px 20px 24px; color:#fff; background-repeat:no-repeat; background-position:center; background-size:cover;}
.statistic_average:after							{content:""; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.5);}
.statistic_average *								{z-index:1; color:#fff;}
.statistic_average .best_score						{margin-bottom:16px;}
.statistic_average .best_score:after				{content:""; position:absolute; bottom:0; left:20px; right:20px; height:1px; border-bottom:1px dashed rgba(255,255,255,.2);}
.statistic_average .best_score:last-child			{margin-bottom:0;}
.statistic_average .best_score:last-child:after		{display:none;}
.statistic_average .best_score span					{margin-bottom:16px; border-color:rgba(255,255,255,.2);}
.statistic_average .best_score span small			{color:rgba(255,255,255,.5);}
.statistic_average .best_score span small q			{font-family:'RobotoR','NotoKrR'; color:rgba(255,255,255,.9);}
.statistic_average .best_score span.on *			{color:#facc56;}


/* 통계 일자별 영역 */
.detail_statistic									{position:relative; z-index:2; margin-top:-20px; padding:24px 20px; background-color:#fff; border-radius:20px 20px 0 0;}


/* 그래프 영역 */
.graph_wrap											{margin-bottom:24px;}
.graph_wrap em										{display:block; margin-bottom:12px; padding-left:20px; font-family:'RobotoB','NotoKrB'; font-size:14px; color:#26282c; background:url('/src/images//icon/i_statistic.png') no-repeat left center; background-size:14px;}


/* 통계 날짜검색 */
.date_form											{position:relative; z-index:2; display:flex; align-items:center; margin:-12px 0 12px; margin-right:-8px; background-color:#fff; border-radius:20px 20px 0 0;}
.date_form label									{position:relative; flex:none; display:flex; align-items:center; margin-right:4px; padding:0 20px 0 8px; height:32px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#26282c; letter-spacing:0; border:1px solid #ebeef0; border-radius:4px;}
.date_form label:after								{content:""; position:absolute; top:50%; right:6px; width:0; height:0; border-style:solid; border-width:6px 4px 0 4px; border-color:#01a368 transparent transparent transparent; transform:translateY(-50%);}
.date_form a										{position:relative; display:flex; align-items:center; padding:0 28px 0 8px; width:100%; height:32px; font-family:'RobotoM','NotoKrM'; font-size:13px; color:#26282c; border:1px solid #ebeef0; border-radius:4px;}
.date_form a:after									{content:""; position:absolute; top:50%; right:4px; width:24px; height:24px; background:url('/src/images//icon/i_calendar.png') no-repeat; background-size:100%; transform:translateY(-50%);}
.date_form span										{padding:0 4px;}
.date_form button									{position:relative; flex:0 0 32px; margin-left:4px; height:32px; text-indent:-9999px; background-color:transparent;}
.date_form button:after								{content:""; position:absolute; top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/i_search_statistic.png') no-repeat; background-size:100%; transform:translate(-50%,-50%);}


/* 일일 통계값 */
.status_by li										{margin-bottom:20px;}
.status_by li:last-child							{margin-bottom:0;}
.status_by li em									{display:block; margin-bottom:8px; font-size:13px; color:#484b51; transform:translateX(4px);}
.status_by li article								{padding:16px 8px; border:1px solid #ebeef0; background-color:#f8f9fb; border-radius:12px;}
.status_by li article .data_score					{position:relative; display:flex; margin-bottom:12px; padding-bottom:12px;}
.status_by li article .data_score:after				{content:""; position:absolute; bottom:0; left:16px; right:16px; height:1px; border-bottom:1px dashed #dee3e6;}
.status_by li article .data_score:last-child		{margin-bottom:0; padding-bottom:0;}
.status_by li article .data_score:last-child:after	{display:none;}
.status_by li article .data_score span				{display:flex; flex-direction:column; width:100%; font-family:'RobotoL','NotoKrL'; color:#26282c; text-align:center; border-right:1px dashed #dee3e6;}
.status_by li article .data_score span:last-child	{border-right:none;}
.status_by li article .data_score span b			{margin-bottom:5px; font-size:11px;}
.status_by li article .data_score span strong		{display:flex; justify-content:center; align-items:center; margin-bottom:2px; font-family:'RobotoB','NotoKrB'; font-size:17px; letter-spacing:0;}
.status_by li article .data_score span strong sub	{font-size:13px; transform:translate(1px,1px);}
.status_by li article .data_score span small		{font-size:10px; color:#8c949e; letter-spacing:0;}
.status_by li article .data_score span small q		{font-family:'RobotoR','NotoKrR'; color:#26282c;}
.status_by li article .data_score span sup			{margin-top:4px; font-family:'RobotoM','NotoKrM'; font-size:12px;}
.status_by li article .data_score span.exclusive	{flex-direction:row; justify-content:flex-end; align-items:center; padding:0 16px; height:20px;}
.status_by li article .data_score span.exclusive b,
.status_by li article .data_score span.exclusive strong	{margin-bottom:0;}
.status_by li article .data_score span.exclusive b		{margin-right:auto;}
.status_by li article .data_score span.exclusive small	{margin-right:8px;}


/* 라운드기록 정보 */
.record_round										{position:relative; display:flex; justify-content:space-between; align-items:center; padding:60px 20px 36px; color:#fff; background-repeat:no-repeat; background-position:center; background-size:cover;}
.record_round:after									{content:""; position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,.5);}
.record_round *										{z-index:1;}
.record_round .field								{display:flex; flex-direction:column;}
.record_round .field span							{margin-bottom:6px;}
.record_round .field span:last-child				{margin-bottom:0;}
.record_round .field em								{margin-bottom:8px; font-family:'RobotoM','NotoKrM'; font-size:20px; line-height:24px;}
.record_round .field small							{position:relative; margin-right:28px; font-size:12px;}
.record_round .field small:after					{content:""; position:absolute; top:50%; right:-18px; width:3px; height:3px; background-color:#fff; border-radius:50%; transform:translateY(-50%);}
.record_round .field small:last-child				{margin-right:0;}
.record_round .field small:last-child:after			{display:none;}
.record_round .field_score							{flex:0 0 60px; display:flex; flex-direction:column; margin-left:auto; text-align:right;}
.record_round .field_score small					{margin-bottom:4px; font-family:'RobotoL','NotoKrL'; font-size:12px; color:rgba(255,255,255,.7);}
.record_round .field_score b						{font-family:'RobotoB'; font-size:32px; color:#fff;}
.section_round										{position:relative; z-index:2; margin:-20px 0 8px; border-bottom:1px solid #f1f3f5; background-color:#fff; border-radius:20px; overflow:hidden;}


/* 라운드기록 유저 성적 */
.round_grade li										{position:relative; display:flex; flex-direction:column; padding:12px 20px;}
.round_grade li:after								{content:""; position:absolute; top:100%; left:0; width:100%; height:1px; background-color:#f1f3f5;}
.round_grade li a									{display:block; position:relative; padding-right:24px;}
.round_grade li a:before,
.round_grade li a:after								{content:""; position:absolute; top:50%; right:6px; margin-top:-1px; width:2px; height:8px; background-color:#c6cbd0; transition:.15s;}
.round_grade li a:before							{margin-right:-1px; transform:rotate(-45deg) translateY(-50%);}
.round_grade li a:after								{transform:rotate(45deg) translateY(-50%);}
.round_grade li a.on:before,
.round_grade li a.on:after							{margin-top:-3px; background-color:#01a368;}
.round_grade li a.on:before							{margin-right:-3px; transform:rotate(135deg);}
.round_grade li a.on:after							{margin-right:2px; transform:rotate(-135deg);}
.round_grade li aside								{display:none; padding:12px 0 4px;}
.round_grade li a.on + aside						{display:block;}
.scorecard_all										{width:100%; height:36px; font-family:'RobotoL','NotoKrL'; font-size:12px; color:#696e76; background-color:transparent;}
.scorecard_all b									{position:relative; padding-right:24px;}
.scorecard_all b:after								{content:""; position:absolute; top:50%; right:0; width:16px; height:16px; background:url('/src/images//icon/i_scorecard.png') no-repeat; background-size:100%; transform:translateY(-50%);}


/* 라운드기록 유저 리스트 */
.user_record										{display:flex; align-items:center; color:#26282c;}
.user_record span									{display:flex; align-items:center; width:100%;}
.user_record span p									{flex:0 0 36px; height:36px; text-indent:-9999px; background-color:rgba(120,137,155,.1); background-repeat:no-repeat; background-position:center; background-size:cover; border-radius:50%;}
.user_record span em								{padding-left:8px; font-family:'RobotoM','NotoKrM'; font-size:15px;}
.user_record strong									{flex:none; font-family:'RobotoB'; font-size:18px;}


/* 라운드 상세기록 */
.detailed_record									{position:relative; display:flex; flex-direction:column; margin-bottom:8px; padding-bottom:40px; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.detailed_record .tab_button						{margin:0 0 32px;}
.detailed_record .tab_button button					{font-size:14px;}
.detailed_record .trace								{padding:0 20px;}


/* 미니맵 */
.mini_map											{padding:20px; text-align:center; border:1px solid #f1f3f5; border-radius:20px;}
.mini_map em										{display:block; margin-bottom:24px; font-size:16px; color:#26282c; letter-spacing:0;}
.mini_map em b										{margin-right:4px; font-family:'RobotoB','NotoKrB';}


/* 미니맵 궤적 */
.tracing											{position:relative; margin:0 auto; width:320px;}
.tracing em,
.tracing span										{position:absolute; text-indent:-9999px;}
.tracing em											{z-index:2; width:12px; height:12px; background-color:#ff4b76; border-radius:50%;}
.tracing span										{z-index:1; height:2px; background-color:#facc56;}


/* 샷 영상 링크 목록 */
.video_link											{position:relative; margin:0 -20px 24px;}
.video_link:before,
.video_link:after									{content:""; position:absolute; top:0; z-index:1; width:20px; height:100%;}
.video_link:before									{left:0; background-image:linear-gradient(to right, #fff 20%, rgba(255,255,255,0) 100%);}
.video_link:after									{right:0; background-image:linear-gradient(to left, #fff 20%, rgba(255,255,255,0) 100%);}
.video_link span									{display:flex; padding:0 20px; overflow-x:auto;}
.video_link span a									{position:relative; flex:none; display:flex; align-items:center; margin-right:10px; padding:0 8px 0 28px; height:24px; font-family:'RobotoL','NotoKrL'; font-size:12px; color:#696e76; border:1px solid #dde1e4; border-radius:12px;}
.video_link span a:last-child						{margin-right:0;}
.video_link span a:before,
.video_link span a:after							{content:""; position:absolute; top:50%; transform:translateY(-50%);}
.video_link span a:before							{left:3px; width:18px; height:18px; background-color:#ebeef0; border-radius:50%;}
.video_link span a:after							{left:8px; width:8px; height:8px; background-color:#c6cbd0;}
.video_link span a strong							{margin-right:4px; font-family:'RobotoM','NotoKrM';}
.video_link span a.on								{color:#01a368; border-color:#01a368;}
.video_link span a.on:before						{background-color:#01a368;}
.video_link span a.on:after							{left:9px; width:0; height:0; border-style:solid; border-width:4px 0 4px 8px; border-color:transparent transparent transparent #fff; background-color:transparent;}


/* 나의 샷 정보 */
.info_grade											{display:flex; flex-wrap:wrap; margin:0 -3px -6px;}
.info_grade li										{margin-bottom:6px; padding:0 3px; width:50%;}
.info_grade li dl									{display:flex; justify-content:space-between; align-items:center; padding:0 8px; height:32px; font-size:11px; color:#26282c; background-color:#f7f7f7;}
.info_grade li dl dd								{font-family:'RobotoL','NotoKrL';}
.info_grade li dl dt								{flex:none; font-family:'RobotoM','NotoKrM';}
.info_grade li dl dt b								{color:#01a368;}


/* 스트로크 멤버 */
.mem_stroke											{padding:0 2px;}
.mem_stroke .user_record							{margin-bottom:8px;}
.mem_stroke .user_record span p						{flex-basis:30px; height:30px;}
.mem_stroke .user_record span em					{font-size:14px;}
.mem_stroke .user_record strong						{font-size:16px;}


/* 포섬 나의 팀 */
.foursome_grade										{display:flex; flex-direction:column; padding:12px 20px 16px; border-bottom:1px solid #f1f3f5;}
.foursome_grade .myteam								{display:flex; align-items:center;}
.foursome_grade .myteam article						{padding-bottom:4px; width:100%;}
.foursome_grade .myteam article .user_record		{flex-direction:column;}
.foursome_grade .myteam article .user_record span	{margin-bottom:8px;}
.foursome_grade .myteam article .user_record span p	{flex-basis:30px; height:30px;}
.foursome_grade .myteam article .user_record span em{font-size:14px;}
.foursome_grade .myteam i							{position:relative; flex:0 0 40px; padding-top:44px; font-family:'RobotoL','NotoKrL'; font-size:11px; text-align:center; transform:translateY(-6px);}
.foursome_grade .myteam i:before					{content:""; position:absolute; top:0; left:50%; width:40px; height:40px; background:url('/src/images//icon/i_team.png') no-repeat; background-size:auto 100%; transform:translateX(-50%);}
.foursome_grade .myteam i.team_1:before				{background-position-x:0;}
.foursome_grade .myteam i.team_2:before				{background-position-x:-40px;}
.foursome_grade .myteam i.team_3:before				{background-position-x:-80px;}
.foursome_grade .myteam i.team_4:before				{background-position-x:-120px;}


/* 포섬 멤버 */
.mem_foursome li									{position:relative; display:flex; align-items:center; margin-bottom:8px; padding:10px 16px; border-width:1px; border-style:solid; border-radius:4px;}
.mem_foursome li i									{position:relative; flex:0 0 30px; margin-right:12px; padding-top:30px; font-family:'RobotoL','NotoKrL'; font-size:10px; text-align:center;}
.mem_foursome li i:before							{content:""; position:absolute; top:0; left:50%; width:28px; height:28px; background:url('/src/images//icon/i_team.png') no-repeat; background-size:auto 100%; transform:translateX(-50%);}
.mem_foursome li strong								{flex:none; font-family:'RobotoM'; font-size:24px;}
.mem_foursome li.team_1								{border-color:#6db5ff;}
.mem_foursome li.team_1 i,
.mem_foursome li.team_1 strong						{color:#6db5ff;}
.mem_foursome li.team_2								{border-color:#ff8094;}
.mem_foursome li.team_2 i,
.mem_foursome li.team_2 strong						{color:#ff8094;}
.mem_foursome li.team_3								{border-color:#96e676;}
.mem_foursome li.team_3 i,
.mem_foursome li.team_3 strong						{color:#96e676;}
.mem_foursome li.team_4								{border-color:#facb55;}
.mem_foursome li.team_4 i,
.mem_foursome li.team_4 strong						{color:#facb55;}
.mem_foursome li.team_1 i:before					{background-position-x:0;}
.mem_foursome li.team_2 i:before					{background-position-x:-28px;}
.mem_foursome li.team_3 i:before					{background-position-x:-56px;}
.mem_foursome li.team_4 i:before					{background-position-x:-84px;}
.mem_foursome li .user_record						{flex-direction:column; width:100%;}
.mem_foursome li .user_record span					{margin-bottom:4px;}
.mem_foursome li .user_record span:last-child		{margin-bottom:0;}
.mem_foursome li .user_record span p				{flex-basis:24px; height:24px;}
.mem_foursome li .user_record span em				{font-size:12px;}

/* 스크램블 나의 팀 */
.scramble_grade										{display:flex; flex-direction:column; padding:12px 20px 16px; border-bottom:1px solid #f1f3f5;}
.scramble_grade .myteam								{display:flex; align-items:center;}
.scramble_grade .myteam article						{padding-bottom:4px; width:100%;}
.scramble_grade .myteam article .user_record		{flex-direction:column;}
.scramble_grade .myteam article .user_record span	{margin-bottom:8px;}
.scramble_grade .myteam article .user_record span p	{flex-basis:30px; height:30px;}
.scramble_grade .myteam article .user_record span em{font-size:14px;}
.scramble_grade .myteam i							{position:relative; flex:0 0 40px; padding-top:44px; font-family:'RobotoL','NotoKrL'; font-size:11px; text-align:center; transform:translateY(-6px);}
.scramble_grade .myteam i:before					{content:""; position:absolute; top:0; left:50%; width:40px; height:40px; background:url('/src/images//icon/i_team.png') no-repeat; background-size:auto 100%; transform:translateX(-50%);}
.scramble_grade .myteam i.team_1:before				{background-position-x:0;}
.scramble_grade .myteam i.team_2:before				{background-position-x:-40px;}

/* 스크램블 멤버 */
.mem_scramble li									{position:relative; display:flex; align-items:center; margin-bottom:8px; padding:10px 16px; border-width:1px; border-style:solid; border-radius:4px;}
.mem_scramble li i									{position:relative; flex:0 0 30px; margin-right:12px; padding-top:30px; font-family:'RobotoL','NotoKrL'; font-size:10px; text-align:center;}
.mem_scramble li i:before							{content:""; position:absolute; top:0; left:50%; width:28px; height:28px; background:url('/src/images//icon/i_team.png') no-repeat; background-size:auto 100%; transform:translateX(-50%);}
.mem_scramble li strong								{flex:none; font-family:'RobotoM'; font-size:24px;}
.mem_scramble li.team_1								{border-color:#6db5ff;}
.mem_scramble li.team_1 i,
.mem_scramble li.team_1 strong						{color:#6db5ff;}
.mem_scramble li.team_2								{border-color:#ff8094;}
.mem_scramble li.team_2 i,
.mem_scramble li.team_2 strong						{color:#ff8094;}
.mem_scramble li.team_1 i:before					{background-position-x:0;}
.mem_scramble li.team_2 i:before					{background-position-x:-28px;}
.mem_scramble li .user_record						{flex-direction:column; width:100%;}
.mem_scramble li .user_record span					{margin-bottom:4px;}
.mem_scramble li .user_record span:last-child		{margin-bottom:0;}
.mem_scramble li .user_record span p				{flex-basis:24px; height:24px;}
.mem_scramble li .user_record span em				{font-size:12px;}

/* 샷 정보 공개여부 아이콘 */
.secret												{position:absolute; top:50%; right:16px; width:24px; height:24px; text-indent:-9999px; background-color:transparent; background-repeat:no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.secret input										{display:none; width:0; height:0;}
.secret label										{display:block; position:relative; width:100%; height:100%; text-indent:-9999px; background:url('/src/images//top/top_lock.png') no-repeat; background-position-x:right; background-size:auto 100%;}
.secret input:checked + label						{background-position-x:0;}


/* 라운드 샷 정보 */
.roundshot_info										{display:flex; flex-direction:column; padding:16px 16px 0; color:#26282c; background-color:#fff;}
.roundshot_info dl									{display:flex; align-items:center;}
.roundshot_info dl dt								{width:100%; font-family:'RobotoB','NotoKrB'; font-size:16px;}
.roundshot_info dl dd								{flex:none; font-family:'RobotoL','NotoKrL'; font-size:12px; color:#aab0b8;}
.roundshot_info span								{margin-top:8px;}
.roundshot_info span small							{position:relative; margin-right:20px; font-size:12px;}
.roundshot_info span small:after					{content:""; position:absolute; top:50%; right:-14px; width:2px; height:2px; background-color:#26282c; border-radius:50%; transform:translateY(-50%);}
.roundshot_info span small:last-child				{margin-right:0;}
.roundshot_info span small:last-child:after			{display:none;}
.roundshot_info span:nth-child(3) small				{font-family:'RobotoL','NotoKrL'; color:#8c949e;}
.roundshot_info span:nth-child(3) small:after		{background-color:#8c949e;}
.roundshot_info .basic_marks						{display:flex; align-items:center; margin-top:16px; height:50px; border-top:1px solid #f1f3f5;}
.roundshot_info .basic_marks i						{display:flex; align-items:center; margin-right:3px; padding:0 12px; height:18px; font-size:11px; color:rgba(255,255,255,.8); background-color:#01a368; border-radius:9px; transform:translateY(-1px); letter-spacing:0; white-space:nowrap;}
.roundshot_info .basic_marks i:last-of-type					{margin-right:12px;}
.roundshot_info .basic_marks i.good						{background-color:#fce500; color:#000;}
.roundshot_info .basic_marks i.nice						{background-color:#FF8000;}
.roundshot_info .basic_marks i.perfect						{background-color:#f00;}
.roundshot_info .basic_marks i.ceremony						{background-color:#f00;}
.roundshot_info .basic_marks i.holein						{background-color:#8000FF;}
.roundshot_info .basic_marks em						{font-size:14px;}
.roundshot_info .basic_marks em b					{margin-right:4px; font-family:'RobotoB','NotoKrB';}
.roundshot_info .basic_marks .score_point			{flex:none; display:flex; flex-direction:row-reverse; align-items:center; margin-left:auto;}
.roundshot_info .basic_marks .score_point small		{margin:0 8px;}


/* 라운드 샷 영상 */
.player_wrap										{position:relative; z-index:1; margin-bottom:-1px; background-color:#26282c;}
.player_wrap .storage								{display:flex; align-items:center; padding:0 16px; height:40px;}
.player_wrap .storage button						{position:relative; margin-right:12px; padding:0 12px; height:24px; font-size:11px; color:rgba(255,255,255,.8); text-indent:16px; background-color:#000; border-radius:12px;}
.player_wrap .storage button:before					{content:""; position:absolute; top:50%; left:10px; width:14px; height:14px; background:url('/src/images//icon/i_storage.png') no-repeat; background-size:auto 100%; transform:translateY(-50%);}
.player_wrap .storage button.keep:before			{background-position-x:-14px;}
/* .player_wrap .storage button.keep:before			{background-position-x:left;} */
.player_wrap .storage button.save:before			{background-position-x:right;}
.player_wrap .storage button.keep						{color:#ff8e4f; opacity:1;}
/*
.player_wrap .storage button.keep					{opacity:.2;}
.player_wrap .storage button.keep.on				{color:#ff8e4f; opacity:1;}
 */
.player_wrap .storage button.keep.on							{color:red; font-family:"NotoKrB";}
/* .player_wrap .storage button.keep.on:before			{background-position-x:-14px;} */
.player_wrap .storage button.keep.on:before,
.player_wrap .storage button.keep.on:after					{content:""; position:absolute; top:50%; left:15px; background-image:none; width:2px; height:14px; background-color:red;}
.player_wrap .storage button.keep.on:before				{transform:translateY(-50%) rotate(45deg);}
.player_wrap .storage button.keep.on:after				{transform:translateY(-50%) rotate(-45deg);}


.player_wrap .storage a								{margin-left:auto; width:24px; height:24px; text-indent:-9999px; background:url('/src/images//icon/logo_instagram.png') no-repeat; background-size:100%; border-radius:50%;}
.player_wrap .video_container						{position:relative; padding:0 0 56.25%; height:0; overflow:hidden;}
.player_wrap .video_container video					{position:absolute; top:0; left:0; width:100%; height:100%;}
.player_wrap .video_container > a					{position:absolute; top:50%; left:50%; z-index:1; width:60px; height:60px; text-indent:-9999px; border:2px solid #fff; border-radius:50%; transform:translate(-50%,-50%);}
.player_wrap .video_container > a:after				{content:""; position:absolute; top:50%; left:50%; width:36px; height:36px; background:url('/src/images//icon/i_play.png') no-repeat; background-size:auto 100%; transform:translate(-50%,-50%);}
.player_wrap .video_container > a.play:after		{background-position-x:left;}
.player_wrap .video_container > a.stop:after		{background-position-x:right;}
.player_wrap .video_container > span				{display:none;}
.player_wrap .video_container .speed				{position:absolute; top:12px; right:12px; z-index:1; background-color:rgba(0,0,0,.8); border-radius:4px; overflow:hidden;}
.player_wrap .video_container .speed button			{display:none; width:60px; height:36px; font-family:'RobotoB'; font-size:15px; color:rgba(255,255,255,.5); background-color:transparent; text-align:center;}
.player_wrap .video_container .speed.open button	{display:block;}
.player_wrap .video_container .speed button.on		{display:block; color:#fff;}
.player_wrap .video_container.empty					{background-color:rgba(255,255,255,.03);}
.player_wrap .video_container.empty video,
.player_wrap .video_container.empty > a,
.player_wrap .video_container.empty .speed			{display:none;}
.player_wrap .video_container.empty > span			{display:block; position:absolute; top:50%; left:50%; z-index:1; padding-top:72px; font-size:13px; color:#fff; text-align:center; transform:translate(-50%,-50%); opacity:.15;}
.player_wrap .video_container.empty > span:before,
.player_wrap .video_container.empty > span:after	{content:""; position:absolute; left:50%; border-style:solid; border-color:#fff; transform:translateX(-50%);}
.player_wrap .video_container.empty > span:before	{top:0; width:60px; height:60px; border-width:2px; border-radius:50%;}
.player_wrap .video_container.empty > span:after	{top:15px; width:4px; height:30px; border-width:22px 0 4px 0;}
.player_wrap .video_controls						{position:relative; padding:0 20px; height:40px;}
.player_wrap .video_controls:before					{content:""; position:absolute; top:50%; left:20px; right:20px; height:2px; background-color:rgba(255,255,255,.1); transform:translateY(-50%);}
.player_wrap .video_controls span					{display:flex; position:relative; top:50%; width:100%; height:2px; background-color:#01a368; transform:translateY(-50%);}
.player_wrap .video_controls span a					{position:absolute; top:50%; right:-10px; width:20px; height:20px; text-indent:-9999px; background-color:#01a368; border-radius:50%; transform:translateY(-50%);}
.player_wrap .video_controls span a:after			{content:""; position:absolute; top:50%; left:50%; width:8px; height:8px; background-color:rgba(0,0,0,.15); border-radius:50%; transform:translate(-50%,-50%);}
.player_wrap .video_thumbnail						{display:flex; padding:0 12px 12px;}
.player_wrap .video_thumbnail li					{display:flex; flex-direction:column; padding:0 4px; width:100%;}
.player_wrap .video_thumbnail li p					{position:relative; height:80px; text-indent:-9999px; background-repeat:no-repeat; background-position:center; background-size:cover;}
.player_wrap .video_thumbnail li p:before,
.player_wrap .video_thumbnail li p:after			{content:""; position:absolute; z-index:1;}
.player_wrap .video_thumbnail li p:before			{top:-1px; bottom:-1px; left:-1px; right:-1px; border:1px solid rgba(255,255,255,.4); background-color:rgba(0,0,0,.6);}
.player_wrap .video_thumbnail li p:after			{top:50%; left:50%; width:24px; height:24px; background:url('/src/images//icon/thumb_play.png') no-repeat center; background-size:100%; transform:translate(-50%,-50%);}
.player_wrap .video_thumbnail li span				{margin-top:8px; font-family:'RobotoM','NotoKrM'; font-size:11px; color:rgba(255,255,255,.4); text-align:center;}
.player_wrap .video_thumbnail li.on p:before		{border:2px solid #01a368; background-color:transparent;}
.player_wrap .video_thumbnail li.on p:after			{display:none;}
.player_wrap .video_thumbnail li.on span			{color:#fff;}
.player_wrap .video_thumbnail li.empty p			{background-image:url('/src/images//sub/noimage_ci_w.png'); opacity:.2;}
.player_wrap .video_thumbnail li.empty p:before		{background-color:transparent;}
.player_wrap .video_thumbnail li.empty p:after		{display:none;}
.player_wrap .video_thumbnail li.empty span			{color:rgba(255,255,255,.1);}


/* 예약 정보 */
.reserve_status > li								{position:relative; display:flex; flex-direction:column; margin-bottom:8px; padding:24px 16px 20px; border-width:1px 0 1px 0; border-style:solid; border-color:#f1f3f5; background-color:#fff;}
.reserve_status > li ul								{margin-bottom:4px;}
.reserve_status > li ul li							{position:relative; display:flex; align-items:center; margin-bottom:12px; padding-left:12px; color:#26282c;}
.reserve_status > li ul li:before					{content:""; position:absolute; top:50%; left:2px; width:4px; height:4px; background-color:#01a368; border-radius:50%; transform:translateY(-50%);}
.reserve_status > li ul li span						{margin-right:12px; font-family:'RobotoL','NotoKrL'; font-size:16px;}
.reserve_status > li ul li b						{padding-right:60px; font-family:'RobotoM','NotoKrM'; font-size:16px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
.reserve_status > li .check							{position:relative; margin-bottom:12px; padding-left:18px; font-family:'RobotoB','NotoKrB'; font-size:12px; color:#01a368;}
.reserve_status > li .check:after					{content:""; position:absolute; top:50%; left:0; width:12px; height:12px; background:url('/src/images//icon/checkbox_i.png') no-repeat; background-size:auto 100%; background-position-x:right; transform:translateY(-50%);}
.reserve_status > li.cancel:after					{content:""; position:absolute; top:40px; bottom:0; left:0; z-index:1; width:100%; background-color:rgba(255,255,255,.85);}
.reserve_status > li.cancel .check					{color:#c6cbd0;}
.reserve_status > li.cancel .check:after			{background-position-x:left;}
.reserve_status > li.d-day .imminent span			{display:none;}
.reserve_status > li.d-day .imminent i				{display:block;}
.reserve_status .summary							{position:relative; display:flex; flex-wrap:wrap;}
.reserve_status .summary dl							{display:flex; align-items:center; margin-bottom:8px; padding:0 10px; width:50%; color:#26282c;}
.reserve_status .summary dl dt,
.reserve_status .summary dl dd						{flex:none; display:flex; align-items:center;}
.reserve_status .summary dl dt						{margin-right:8px; padding:0 6px; height:18px; font-family:'RobotoM','NotoKrM'; font-size:11px; border:1px solid #c6cbd0; border-radius:2px;}
.reserve_status .summary dl dd						{font-size:13px;}
.reserve_status .summary dl dd sub					{position:relative; padding-left:16px; font-size:12px; color:#8c949e; letter-spacing:0;}
.reserve_status .summary dl dd sub:before			{content:""; position:absolute; top:50%; left:8px; width:1px; height:10px; background-color:#f1f3f5; transform:translateY(-50%);}
.reserve_status .summary button						{position:absolute; top:50%; right:0; z-index:1; margin-top:-4px; width:48px; height:48px; text-indent:-9999px; background:url('/src/images//icon/i_call.png') no-repeat center; background-size:20px; border:1px solid #f1f3f5; border-radius:50%; transform:translateY(-50%);}
.reserve_status .action								{display:flex; justify-content:center; align-items:center; padding-top:10px; height:40px;}
.reserve_status .action button						{margin-right:8px; width:100%; height:40px; font-family:'RobotoB','NotoKrB'; font-size:12px; color:#696e76; border:1px solid #ebeef0; background-color:transparent;}
.reserve_status .action button:last-child			{margin-right:0; color:#01a368; border-color:#01a368;}
.reserve_status .action span						{position:relative; padding-left:22px; font-size:14px; color:#aab0b8;}
.reserve_status .action span:before,
.reserve_status .action span:after					{content:""; position:absolute; top:50%; z-index:1; transform:translateY(-50%);}
.reserve_status .action span:before					{left:0; width:16px; height:16px; background-color:#ebeef0; border-radius:50%;}
.reserve_status .action span:after					{left:7px; width:2px; height:9px; background-image:url('/src/images//icon/i_emphasis.png'); background-repeat:no-repeat; background-size:auto 100%; background-position-x:-2px;}
.reserve_status .imminent span,
.reserve_status .imminent i							{position:absolute; right:16px;}
.reserve_status .imminent span						{top:24px; font-family:'RobotoM','NotoKrM'; font-size:16px; color:#01a368;}
.reserve_status .imminent i							{display:none; position:absolute; top:12px; z-index:1; width:40px; height:40px; text-indent:-9999px; background:url('/src/images//icon/i_dday.png') no-repeat center; background-size:100%;}


/* 회원탈퇴 */
.leave												{display:flex; flex-direction:column; margin-top:32px; padding:0 2px;}
.leave strong										{font-family:'RobotoB','NotoKrB'; font-size:24px; color:#26282c;}
.leave span											{margin-top:16px; font-size:14px; color:#8c949e;}
.caution											{margin-top:36px; padding:24px 16px; background-color:#f1f3f5; border-radius:10px;}
.caution em											{display:block; position:relative; margin-bottom:16px; padding-left:24px; font-family:'RobotoM','NotoKrM'; font-size:16px; color:#26282c; line-height:16px;}
.caution em:before,
.caution em:after									{content:""; position:absolute; z-index:1;}
.caution em:before									{top:0; left:0; width:16px; height:16px; background-color:#01a368; border-radius:50%;}
.caution em:after									{top:3px; left:7px; width:2px; height:9px; background-image:url('/src/images//icon/i_emphasis.png'); background-repeat:no-repeat; background-position:left top; background-size:auto 100%;}
.caution ol											{padding:0 4px;}
.caution ol li										{position:relative; display:flex; align-items:center; margin-top:8px; padding-left:12px; font-size:14px; color:#484b51; line-height:18px;}
.caution ol li:before								{content:""; position:absolute; top:7px; left:2px; width:4px; height:4px; background-color:#484b51; border-radius:50%;}


/* 앱 접근권한 */
.access												{padding-top:16px;}
.access em											{display:block; margin-bottom:40px; font-family:'RobotoB','NotoKrB'; font-size:18px; color:#26282c; line-height:24px;}
.access strong										{display:block; margin-bottom:32px; font-family:'RobotoB','NotoKrB'; font-size:13px; color:#01a368;}
.access ul li										{display:flex; margin-bottom:24px;}
.access ul li p										{position:relative; flex:0 0 56px; margin-right:12px; height:56px; text-indent:-9999px; background-color:#f2f4f6; border-radius:50%;}
.access ul li p:after								{content:""; position:absolute; top:50%; left:50%; width:36px; height:36px; background:url('/src/images//icon/i_access.png') no-repeat; background-size:auto 100%; transform:translate(-50%,-50%);}
.access ul li p.icon_address:after					{background-position-x:0;}
.access ul li p.icon_call:after						{background-position-x:-36px;}
.access ul li p.icon_camera:after					{background-position-x:-72px;}
.access ul li p.icon_storage:after					{background-position-x:-108px;}
.access ul li p.icon_location:after					{background-position-x:-144px;}
.access ul li dl									{padding-top:8px;}
.access ul li dl dt									{margin-bottom:8px; font-family:'RobotoB','NotoKrB'; font-size:16px; color:#26282c;}
.access ul li dl dd									{font-size:14px; color:#8c949e; line-height:16px;}

/*스크롤 방지*/
body.no-scroll 								{overflow: hidden !important}






